import React from 'react';
import './Landing.css';
import img3d from '../../Images/3D-Leaflet-931x1024.jpg';
import morter3d from '../../Images/3D-Mortar-M-30-50Kg-Back-1013x1024.png';

const PrintingMortar = () => {
    const printingMortar = {
        "name": "3D PRINTING MORTAR",
        "description": "As the world moves towards a more sustainable future, it is becoming increasingly important to consider eco-friendly methods of construction. One such method of construction is 3D concrete printing, which has the potential to save tremendous amounts of energy and reduce the need for new construction materials.",
        "advantages": [
            "Single component, just add water",
            "Easy to pump and fast construction",
            "High buildability and good strength",
            "Exceeds International standards",
            "Easy to clean",
            "Low impact on environment"
        ],
        "coverage": "Refer to Technical Data Sheet (TDS)",
        "packaging": "50 kg bag",
        "color": "Brown to Grey",
        "availableGrades": ["3DCP M30", "M45", "M60"]
    };

    return (
        <div>
            {/* First Section */}
            <fieldset className='legend-head'>
                <legend align="center" className='legend'>Personalia:</legend>
                <div className='content-printing'>
                    <img src={img3d} alt="" className='img_printing' />
                    <p>MYK LATICRETE 3D printing Concrete, a ready to use powder,
                        is the top-notch choice for 3D Concrete Printing projects
                        due to its exceptional quality, versatility, reliability,
                        and unrivalled performance. It stands out in the industry
                        with its buildability, rheology and flowability suitable
                        for a variety of printing heads like robotic, hydraulic,
                        or pneumatic types and is highly recommended for professionals
                        and enthusiasts alike. With MYK LATICRETE 3D Concrete powder,
                        you can elevate your creations and turn your visions into reality.  </p>
                </div>
            </fieldset>

            <fieldset className='legend-head'>
                <legend align="center" className='legend'>Advantages</legend>
                <div className="advantages-details">
                    <ul>
                        {printingMortar.advantages.map((advantage, index) => (
                            <li key={index}>{advantage}</li>
                        ))}
                    </ul>

                </div>
            </fieldset>
            {/* Second Section */}
            <fieldset className='legend-head'>
                <legend align="center" className='legend'>Products Details</legend>
                <div className='content-printing'>
                    <div className="advantage-wrapper">
                        <img src={morter3d} alt="" className='img_printing' />
                        <div>
                            <p>{printingMortar.name}</p>
                            <p>{printingMortar.description}</p>
                            <p>{printingMortar.coverage}</p>
                        </div>
                    </div>
                </div>
            </fieldset>

        </div>
    );
};

export default PrintingMortar;
