import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import home from '../Images/Kerakoll.pdf';
// import './About.css'; // Import CSS for styling
// import '../Components/Tenex/Tenex.css';
// Enable PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFThumbnailViewer = () => {
    const [file, setFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setViewportWidth(window.innerWidth);
    };

    useEffect(() => {
        getPdf();
    }, []);

    const getPdf = () => {
        setFile(home);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const calculatePageWidth = () => {
        // Adjust page width dynamically based on viewport width
        const maxWidth = 0.9 * viewportWidth; // Adjust as needed
        const calculatedWidth = Math.min(maxWidth, 900); // Set maximum width
        return calculatedWidth;
    };

    return (
        <div className="pdf-viewer-container"> {/* Apply styling for full-screen */}
            {file && (
                <div className="pdf-document-container"> {/* Apply styling for PDF document */}
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={calculatePageWidth()} />
                        ))}
                    </Document>
                </div>
            )}
        </div>
    );
};

export default PDFThumbnailViewer;