import React from 'react';
import img from '../../Images/Fine-Finish.png';
const WallPutty = () => {
    const wallPutty = {
        "img": img,
        "name": "LATAFINISH Wall Putty",
        "description": "LATAFINISH Wall Putty is a unique formulation of white cement and high performance chemicals and polymers which can be applied on damp as well as dry surfaces.",
        "advantages": [
            "White cement based Wall Putty",
            "White, smooth & glossy finish",
            "Does not allow paint to flake",
            "Protects paint from moisture",
            "Curing is not required",
            "Ideal for interior & exterior walls"
        ],
        "coverage": "LATAFINISH Wall Putty covers approx. 16 to 20 sq. ft. per kg for 2 coats, maintaining a maximum thickness of 1.5 mm on smooth plaster surface.",
        "packaging": ["40 kg", "20 kg", "5 kg", "1 kg bags"],
        "compliance": "VOC: Very low"
    };

    return (
        <div className="wall-putty-page">
            <div className="wall-putty-card">
                <div className="product-image">
                    <img src={wallPutty.img} alt={wallPutty.name} />
                </div>
                <h2>{wallPutty.name}</h2>
                <p>{wallPutty.description}</p>
                <p><strong>Advantages:</strong></p>
                <ul>
                    {wallPutty.advantages.map((advantage, index) => (
                        <li key={index}>{advantage}</li>
                    ))}
                </ul>
                <p><strong>Coverage:</strong> {wallPutty.coverage}</p>
                <p><strong>Packaging:</strong> {wallPutty.packaging.join(', ')}</p>
                <p><strong>Compliance:</strong> {wallPutty.compliance}</p>
            </div>
            <style jsx>{`
                .wall-putty-page {
                    margin-top:20px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                }
                
                .wall-putty-card {
                    background-color: #f2f2f2;
                    color:black;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    width: 60%;
                    max-width: 600px;
                }
                .product-image {
                    overflow: hidden;
                    text-align:center;
                    width: 100%;
                    height: auto;
                    }

                    .product-image img {
                    width:50%;
                    height: auto;
                    object-fit: cover;
                    }
                .wall-putty-card h2 {
                    margin-top: 0;
                }
                
                .wall-putty-card ul {
                    margin-top: 5px;
                    padding-left: 20px;
                }
                
                .wall-putty-card li {
                    margin-bottom: 5px;
                }
            `}</style>
        </div>
    );
};

export default WallPutty;
