import { BrowserRouter, Routes, Route } from "react-router-dom";
import Header from "./Components/Header/Header";
import Home from './Components/Home/Landing';
import Footer from './Components/Footer/Footer'
import DetailsPage from "./Components/DtailsPage/DetailsPage";
import Compnaywise from "./Components/CompanyDetails/Compnaywise";
import Screed from "./Components/Home/Screed";
import SoundControl from './Components/Home/SoundControl';
import PrintingMorter from './Components/Home/PrintingMorter';
import CementAddetive from './Components/Home/CementAddetive';
import ScreedMain from './Components/Home/ScreedMain';
import WaterProofing from "./Components/Home/WaterProofing";
import TileAdeshive from "./Components/Home/TileAdeshive";
import Grout from "./Components/Home/Grout";
import StoneCare from "./Components/Home/StoneCare";
import WallPutty from "./Components/Home/WallPutty";
import Contact from "./Components/Login/Contact";
import Tenex from "../src/Components/Tenex/Tenex";
import KeraKoll from "./Components/KeraKoll";
export default function App() {
  return (
    <BrowserRouter>
      <Header />
      <br/><br/><br/><br/>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Routes>
        <Route path="/home" element={<DetailsPage />} />
      </Routes>
      <Routes>
        <Route path="/detail" element={<DetailsPage />} />
      </Routes>
      <Routes>
        <Route path="/category" element={<Compnaywise />} />
      </Routes>
      <Routes>
        <Route path="/screed" element={<Screed />} />
      </Routes>
      <Routes>
        <Route path="/sound-control" element={<SoundControl />} />
      </Routes>
      <Routes>
        <Route path="/printing" element={<PrintingMorter />} />
      </Routes>
      <Routes>
        <Route path="/cement-aditive" element={<CementAddetive />} />
      </Routes>
      <Routes>
        <Route path="/screed-main" element={<ScreedMain />} />
      </Routes>
      <Routes>
        <Route path="/water-proofing" element={<WaterProofing />} />
      </Routes>
      <Routes>
        <Route path="/tile-addisive" element={<TileAdeshive />} />
      </Routes>
      <Routes>
        <Route path="/groot" element={<Grout />} />
      </Routes>
      <Routes>
        <Route path="/stone-care" element={<StoneCare />} />
      </Routes>
      <Routes>
        <Route path="/Contact" element={<Contact />} />
      </Routes>
      <Routes>
        <Route path="/wall-putty" element={<WallPutty />} />
      </Routes>
      <Routes>
        <Route path="tenex" element={<Tenex />} />
      </Routes>
      <Routes>
        <Route path="demo" element={<KeraKoll />} />
      </Routes>
      <br/>
      <Footer />
    </BrowserRouter>
  );
}
