import React from 'react';
import myklogo from '../../Images/myklogo.png';
import Insallsystem from './Insallsystem';
function Compnaywise() {
    return (
        <>
            <div style={{ backgroundColor: 'blue', width: '100%', textAlign: 'center', height: '50px' }}>
                <div>
                    <img src={myklogo} alt="" height='30' style={{ marginTop: '10px' }} />
                </div>
                <br />
            </div>
            <div style={{ textAlign: 'center' }}>
                <iframe width="60%" height="500" src="https://www.youtube.com/embed/I4ttL00SZt4?si=pdfC6AXW1MvJG7mm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
            </div>
            <br />
            <Insallsystem/>
        </>
    )
}

export default Compnaywise
