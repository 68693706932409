import React from 'react';
import img from '../../Images/bedmorter.png';
import myclerate from '../../Images/myclerate.jpg';
import myk315 from '../../Images/myk315.png';
const data = {
  "products": [
    {
      "img": img,
      "name": "LATICRETE 226 THICK BED MORTAR",
      "description": "A factory prepared blend of raw materials, portland cement and graded aggregates, it is designed for use with LATICRETE 3642 Latex Admix and water to produce high strength thick bed adhesive up to 50 mm for installation of all types of ceramic tiles, vitrified tiles & natural stone. The mixture can be used as interior & exterior cement plaster or as screed for floors & walls.",
      "applications": "Interior & exterior floors & wall",
      "advantages": [
        "Premixed factory blended",
        "Can be applied from 12 mm to 50 mm in one application",
        "Two component - to be mixed with L3642 latex concentrate",
        "High strength, flexible, shock and impact resistant"
      ],
      "suitableSubstrates": "Concrete & Cement Mortar Bed",
      "coverage": "Varies by application",
      "packaging": "50 kg bag",
      "colour": ["Grey", "White"]
    },
    {
      "img": myk315,
      "name": "LATICRETE 3642 LATEX CONCENTRATE FOR THICK BED MORTAR",
      "description": "LATICRETE 3642 is a concentrated Latex Admix which is diluted with water on site and then mixed with LATICRETE 226 Thick Bed Mortar to make cement plasters, renders, coatings, mortar beds and screed. It has greatly improved physical characteristics and can be used in exterior, interior as well as underwater plasters, screeds and renders.",
      "advantages": [
        "Increased bond and compressive strength",
        "Water resistant; suitable for submerged applications",
        "Superior flexibility",
        "Can be used for repairs and patching of concrete"
      ],
      "coverage": "Varies by application",
      "packaging": ["5 ltr.", "20 ltr. pails"],
      "compliance": {
        "standards": ["EN 13813, as CT-C20 F6 B2 SH60"],
        "VOC": "Very low"
      }
    },
    {
      "img": myclerate,
      "name": "LATASCREED 100",
      "description": "A factory prepared blend of carefully selected speciality raw materials, portland cement and graded fillers, it is designed for use with water and aggregates to produce high strength, water retaining and self curing screed for interior and exterior floor applications. It meets and exceeds the requirements of National and International Standards.",
      "advantages": [
        "High strength",
        "Shock and impact resistant",
        "Water retaining and self curing",
        "No shrinkage"
      ],
      "suitableSubstrates": [
        "Concrete",
        "Cement Mortar Beds (compressive strength>25 Mpa)",
        "Cement Terrazzo"
      ],
      "coverage": "Coverage will be based on following mix design the grain size matrix has to be uniformly distributed up to a size of 8 mm (Minimum thickness: 12 mm; Maximum thickness: 70 mm)",
      "packaging": "25 kg bag",
      "colour": "Grey",
      "compliance": {
        "standards": ["EN 13813, as CT-C30 F5 B1 5SH50"],
        "VOC": "Practically Nil"
      }
    }
  ]
};

const ProductPage = () => {
  return (
    <div className="product-page">
      <div className='div_head'>
        <h1>Screed</h1>
        <p>
          A screed sits between the structural sub-floor of the actual foundation and the flooring material enabling tile/ stone floor installation on a smooth, level, hard surface.
          MYK LATICRETE offers high quality screed providing strong, levelled surfaces while preventing damage caused by long term exposure to moisture. Especially true in areas such as bathrooms, swimming pools and others, MYK LATICRETE screed is easy to install and offers superior moisture barrier for your flooring.
        </p>
        <div className='div_headings'>
          <h2>Screed Products</h2>
        </div>
      </div>
      {data.products.map((product, index) => (
        <div className="product-card" key={index}>
          <div className="product-image">
            <img src={product.img} alt={product.name} />
          </div>
          <div className="product-details">
            <h2>{product.name}</h2>
            <p className="description">{product.description}</p>
            <div className="details">
              <div>
                <strong>Applications:</strong>
                <p>{product.applications}</p>
              </div>
              <div>
                <strong>Advantages:</strong>
                <ul>
                  {product.advantages.map((advantage, index) => (
                    <li key={index}>{advantage}</li>
                  ))}
                </ul>
              </div>
              <div>
                <strong>Suitable Substrates:</strong>
                <p>{product.suitableSubstrates}</p>
              </div>
              <div>
                <strong>Coverage:</strong>
                <p>{product.coverage}</p>
              </div>
              <div>
                <strong>Packaging:</strong>
                <p>{Array.isArray(product.packaging) ? product.packaging.join(', ') : product.packaging}</p>
              </div>
              <div>
                <strong>Colour:</strong>
                <p>{Array.isArray(product.colour) ? product.colour.join(', ') : product.colour}</p>
              </div>
              {product.compliance && (
                <div>
                  <strong>Compliance Standards:</strong>
                  <ul>
                    {product.compliance.standards.map((standard, index) => (
                      <li key={index}>{standard}</li>
                    ))}
                  </ul>
                  <p><strong>VOC:</strong> {product.compliance.VOC}</p>
                </div>
              )}
            </div>
          </div>
        </div>
      ))}
      <style jsx>{`
        .div_head{
          background-color: #f2f2f2;
          text-align: center;
        }
        .div_headings{
          background-color:blue;
          color:white;
        }
        .product-page {
          display: flex;
          flex-wrap: wrap;
          gap: 40px;
          justify-content: center;
        }

        .product-card {
          background-color: #fff;
          border-radius: 10px;
          box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
          overflow: hidden;
          width: 100%;
          max-width: 800px;
          margin-bottom: 20px;
        }

        .product-image {
          overflow: hidden;
          text-align:center;
          width: 100%;
          height: auto;
        }

        .product-image img {
          width:30%;
          height: auto;
          object-fit: cover;
        }

        .product-details {
          padding: 20px;
        }

        .product-details h2 {
          margin-top: 0;
          margin-bottom: 10px;
          color: #333;
        }

        .product-details p {
          color: #666;
        }

        .description {
          margin-bottom: 20px;
        }

        .details {
          display: grid;
          grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
          gap: 20px;
        }

        .details div {
          margin-bottom: 15px;
        }

        .details strong {
          display: block;
          margin-bottom: 5px;
          color: #333;
        }

        .details ul {
          margin-top: 5px;
          padding-left: 20px;
        }

        .details li {
          color: #666;
        }

        @media (max-width: 768px) {
          .product-card {
            width: 90%;
          }
        }
      `}</style>
    </div>
  );
};

export default ProductPage;
