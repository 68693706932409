
import React from 'react';
import img1 from '../../Images/L-345-Super-Flex.png';
import img2 from '../../Images/L-325-SW-1001x1024.png';
import img3 from '../../Images/L-335-MAXI-25-Kg-MSD-922x1024.png';
import img4 from '../../Images/L-335-SF-MSD-1.png';
import img5 from '../../Images/L-325-HIGH-FLEX-20-Kg-MSD-856x1024.png';
const TileAdhesiveModified = () => {
    const products = [
        {
          "img": img2,
          "name": "LATICRETE 325 SW Adhesive",
          "description": "It is a high polymer modified adhesive for fixing tiles and natural stone on interior and exterior floor & shear walls.",
          "applications": "Designed for interior floor and wall installations and external floor installations of all types of ceramic tile, vitrified tile, precast Cement terrazzo and natural stones over concrete and a variety of cement-based substrates, especially on low absorbent cement substrates like MIVAN / Shear Walls.",
          "advantages": [
            "Formulated for shear wall and low absorbent cementitious substrate",
            "Can be used upto a bed thickness of 15 mm",
            "High bond strength & resistance against shrinkage cracks",
            "Single component - Just add water"
          ],
          "coverage": "Approx. 137-150 sq.ft. per 50 kg bag with a ¼” x ¼” (6mm x 6mm) square notch trowel for a bed of 3 mm",
          "bedThickness": "Can be used upto 15 mm",
          "packaging": "50 kg* bag",
          "colour": "Grey",
          "compliance": {
            "standards": [
              "ANSI A 118.4 T",
              "EN 12004 / ISO 13007: C2T",
              "IS 15477: 2019 : TYPE 2 T ADHESIVE"
            ],
            "VOC": "Practically Nil"
          }
        },
        {
          "img": img3,
          "name": "LATICRETE 335 MAXI MULTIPURPOSE LFT ADHESIVE",
          "description": "A highly polymer modified and highly flexible adhesive for installation of large format / slim tiles on most of the substrates to accommodate movements. It offers very good handling properties with good sag and slump resistance while doing installation on wall or floor respectively. It can be applied up to 18 mm bed thickness.",
          "applications": "Designed especially for interior and exterior floor and wall installations of all types of ceramic tile, vitreous, semi-vitreous tile, paper faced glass mosaic tiles, precast terrazzo and natural stones over concrete and on a variety of substrates. Good underwater shear bond allows this product to be used for wet areas like swimming pools, sauna, water bodies and washrooms. Can be used for tile on tile applications and as slurry bond adhesive in wet on wet applications.",
          "advantages": [
            "For fixing extra large format tiles & thin body tiles on demanding internal & external applications",
            "Highly Flexible, Light weight formula. More than 15% higher coverage than standard adhesive",
            "Cream & non sag formula for easier installation on floor and wall",
            "Non-slump adhesive with a bed thickness up to 18 mm"
          ],
          "suitableSubstrates": [
            "Slim porcelain large format tiles",
            "Vitreous, semi vitreous or non- vitreous tiles; ceramic; quarry, cement body tile",
            "Brick",
            "Cement based precast terrazzo",
            "Natural stone tile"
          ],
          "coverage": "Approx 72 - 78 sft per 25 kg bag with (6mmx6mm) square notch trowel for a bed of 3 mm",
          "bedThickness": "Can be used upto 18 mm",
          "packaging": "25 kg bag",
          "colour": "Grey",
          "compliance": {
            "standards": [
              "ANSI A118.4 ET",
              "EN 12004 / ISO 13007 : C2TE S1",
              "IS 15477: 2019: TYPE 3 TS1 ADHESIVE"
            ],
            "VOC": "Practically Nil"
          }
        },
        {
          "img": img4,
          "name": "LATICRETE 335 SUPER FLEX MULTIPURPOSE FLOOR AND WALL TILE ADHESIVE",
          "description": "A multipurpose high polymer modified adhesive for interior and exterior installation of ceramic tiles, stone, quarry tiles, glass mosaic tiles, pavers and bricks. LATICRETE 335 Super Flex Multipurpose Floor and Wall Tile Adhesive is designed to mix with water and has a convenient open time with high adhesion & workability. LATICRETE 335 Super Flex Multipurpose Floor and Wall Tile Adhesive is compliant with EN and ANSI standards",
          "applications": "Designed for exterior and underwater applications, superior bond to concrete, cement renders, screeds.",
          "advantages": [
            "For fixing tiles & stone on demanding external & internal application",
            "Polymer modified super flexible adhesive with superior bond strength",
            "High performance in all types of weather",
            "Single component - Just add water"
          ],
          "suitableSubstrates": [
            "Concrete",
            "Concrete Masonry",
            "Cement Mortar Beds",
            "Cement Plaster",
            "Ceramic Tile and Stone",
            "Brick Masonry",
            "Cement Backer Board*",
            "Cement Terrazzo",
            "Gypsum Wallboard*"
          ],
          "coverage": "Approx 55 - 60 sft per 20 kg bag with (6mmx6mm) square notch trowel for a bed of 3 mm",
          "bedThickness": "Can be used upto 12 mm",
          "packaging": "20 kg & 50 kg* bags",
          "colour": "Grey & White",
          "compliance": {
            "standards": [
              "ANSI: A 118.4 ET",
              "EN 12004 / ISO 13007 : C2TE S1",
              "IS 15477:2019: TYPE 3 T S1 ADHESIVE"
            ],
            "VOC": "Practically Nil"
          }
        },
        {
          "img": img5,
          "name": "LATICRETE 345 HIGH FLEX Ag \"SILVER\" FLOOR AND WALL TILE ADHESIVE",
          "description": "A multipurpose, high polymer modified powder offering vast utility including applications for floor & wall in interior & exterior areas. It is a cementitious thin-set powder to be mixed only with water to install various types of medium & large format tiles & stone on a variety of substrates.",
          "applications": "Designed specially for interiors, exterior floor & wall installations of all types of ceramic tiles, vitreous tiles, semi-vitireous tiles, glass mosaic tiles, precast terrazzo and natural stones over concrete and a variety of substrates.",
          "advantages": [
            "For fixing tiles & natural stone on interior & exterior floor & wall",
            "Polymer modified bonds with various substrates",
            "Water & Shock resistant",
            "Single component - Just add water"
          ],
          "suitableSubstrates": [
            "Concrete",
            "Cement Mortar Beds",
            "Ceramic Tile and Stone",
            "Concrete Masonry",
            "Cement Plaster",
            "Brick Masonry",
            "Cement Terrazzo",
            "Gypsum Wall Board"
          ],
          "coverage": "Approx. 55-60 sq.ft. per 20 kg bag with a 1/4\" x 1/4\" (6mm x 6mm) square notch trowel for a bed of 3mm.",
          "bedThickness": "Can be used upto 12 mm",
          "packaging": "5 kg, 20 kg and 50 kg* bags",
          "colour": "Grey and White",
          "compliance": {
            "standards": [
              "ANSI: A 118.4 ET",
              "EN 12004 / ISO 13007: C2TE",
              "IS 15477:2019:TYPE 3 T ADHESIVE"
            ],
            "VOC": "Practically Ni"
          }
        }
      ]
      


    return (
        <div className="product-page">
            {products.map((product, index) => (
                <div className="product-card" key={index}>
                    <div className="product-image">
                        <img src={product.img} alt={product.name} />
                    </div>
                    <h2>{product.name}</h2>
                    <p>{product.description}</p>
                    <p><strong>Applications:</strong> {product.applications}</p>
                    <p><strong>Advantages:</strong></p>
                    {product.advantages && (
                        <ul>
                            {product.advantages.map((advantage, index) => (
                                <li key={index}>{advantage}</li>
                            ))}
                        </ul>
                    )}
                    <p><strong>Coverage:</strong> {product.coverage}</p>
                    {product.bedThickness && (
                        <p><strong>Bed Thickness:</strong> {product.bedThickness}</p>
                    )}
                    <p><strong>Packaging:</strong> {product.packaging}</p>
                    <p><strong>Compliance Standards:</strong></p>
                    {product.compliance && product.compliance.standards && (
                        <ul>
                            {product.compliance.standards.map((standard, index) => (
                                <li key={index}>{standard}</li>
                            ))}
                        </ul>
                    )}
                    {product.compliance && product.compliance.VOC && (
                        <p><strong>VOC:</strong> {product.compliance.VOC}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TileAdhesiveModified;