// import React from "react";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
// import myk305 from '../../Images/305.png';
// import myk307 from '../../Images/307.png';
// import myk315 from '../../Images/315.png';
// import myk270 from '../../Images/270-1.png';
// import myk300 from '../../Images/300-1.png';
// import myk325 from '../../Images/L-325-SW-1001x1024.png';
// import myk325Maxi  from '../../Images/L-325-HIGH-FLEX-20-Kg-MSD-856x1024.png';
// import myk335 from '../../Images/L-335-SF-MSD-1.png';
// import myk335sw from '../../Images/L-335-SF-MSD-1.png';
// import mykL345sf from '../../Images/L-345-Super-Flex.png';
// import mykL500sf from '../../Images/L-500-Series-240x300.png';
// import myk600 from '../../Images/L-600-Series.png'
// // import './CustomSlider.css';
// function CustomSlides() {
//     const settings = {
//         dots: true,
//         infinite: true,
//         speed: 400,
//         slidesToShow: 6,
//         slidesToScroll: 6,
//         arrows: true, // Show navigation arrows
//         responsive: [ // Responsive settings
//             {
//                 breakpoint: 768, // Breakpoint for smaller screens
//                 settings: {
//                     slidesToShow: 6,
//                     slidesToScroll: 6
//                 }
//             },
//             {
//                 breakpoint: 992, // Breakpoint for medium-sized screens
//                 settings: {
//                     slidesToShow: 6,
//                     slidesToScroll: 6
//                 }
//             },
//             {
//                 breakpoint: 1200, // Breakpoint for larger screens
//                 settings: {
//                     slidesToShow: 6,
//                     slidesToScroll: 6
//                 }
//             }
//         ]
//     };

//     return (
//         <div className="slider-container" style={{ width: '100%', textAlign: 'center',height:'250px',fontSize:'small'}}>
//             <Slider {...settings} autoplay>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%' }}>
//                         <img src={myk305} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%' }}>
//                         <img src={myk307} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451' ,width: '96%'}}>
//                         <img src={myk315} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%' }}>
//                         <img src={myk270} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%' }}>
//                         <img src={myk300} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={myk325} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={myk325Maxi} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={myk335} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={myk335sw} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={mykL345sf} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={mykL500sf} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//                 <div>
//                     <div style={{ backgroundColor: "white", color: '#FF5451',width: '96%'}}>
//                         <img src={myk600} style={{ width: '100%', height: '200px' }} alt="Slide 1" />
//                         <p>Adhesive 15</p>
//                     </div>
//                 </div>
//             </Slider>
//         </div>
//     );
// }

// export default CustomSlides;

import React from 'react';
import { Card, Col, Row } from 'antd';
// import 'antd/dist/antd.css';
import myk305 from '../../Images/305.png';
import myk307 from '../../Images/307.png';
import myk315 from '../../Images/315.png';
import myk270 from '../../Images/270-1.png';
import myk300 from '../../Images/300-1.png';
import myk325 from '../../Images/L-325-SW-1001x1024.png';
import myk325Maxi from '../../Images/L-325-HIGH-FLEX-20-Kg-MSD-856x1024.png';
import myk335 from '../../Images/L-335-SF-MSD-1.png';
import myk335sw from '../../Images/L-335-SF-MSD-1.png';
import mykL345sf from '../../Images/L-345-Super-Flex.png';
import mykL500sf from '../../Images/L-500-Series-240x300.png';
import myk600 from '../../Images/L-600-Series.png';

const { Meta } = Card;

const cardData = [
     { title: 'Adhesive 270 ', image: myk270 },
     { title: 'Adhesive 300', image: myk300 },
    { title: 'Adhesive 305', image: myk305 },
    { title: 'Adhesive 307', image: myk307 },
    { title: 'Adhesive 315', image: myk315 },
    { title: 'Adhesive 325', image: myk325 },
    { title: 'Adhesive 325 maxi', image: myk325Maxi },
    { title: 'Adhesive 335', image: myk335 },
    { title: 'Adhesive 335 super flex', image: myk335sw },
    { title: 'Adhesive 345 super flex ', image: mykL345sf },
    { title: 'Adhesive 500', image: mykL500sf },
    { title: 'Adhesive 600', image: myk600 },
];

const ResponsiveCardGrid = () => {
    return (
        <div style={{ padding: '30px' }}>
            <Row gutter={[16, 16]}>
                {cardData.map((card, index) => (
                    <Col key={index} xs={24} sm={12} md={8} lg={6} xl={4}>
                        <Card
                            hoverable
                            cover={<img alt={card.title} src={card.image} style={{ height: '200px', objectFit: 'cover' }} />}
                        >
                            <Meta title={card.title} />
                        </Card>
                    </Col>
                ))}
            </Row>
        </div>
    );
};

export default ResponsiveCardGrid;
