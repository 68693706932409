import { Select } from 'antd';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function CompanyCategory({ categoryList, setCategoryList,toggleDrawer }) {
    const navigate = useNavigate();

    const CategorySelect = (value) => {
        navigate('/home', { state: { category: value } });
        setCategoryList(value);
        toggleDrawer()
        // Scroll the window to a specific position (e.g., 1500px from the top)
        window.scrollTo({
            top: 1500,
            behavior: 'smooth' // Optional: for smooth scrolling
        });
    };

    return (
        <div>
            <Select
                value={categoryList}
                onChange={CategorySelect}
                style={{ width: '150px' }}
                placeholder="Select Company"
            >
                <Select.Option value="MYKLATICRETE">MYKLATICRETE</Select.Option>
                <Select.Option value="Kera Koll">Kera Koll</Select.Option>
                <Select.Option value="Tenex">Tenex</Select.Option>
                <Select.Option value="Akemi">Akemi</Select.Option>
                <Select.Option value="other">Other</Select.Option>
            </Select>
        </div>
    );
}

export default CompanyCategory;
