
import React from 'react';
import TileAdhesiveBased from './TileAdhesiveBased';
import img1 from '../../Images/l-111-300x298.png';
import img2 from '../../Images/73-pail-266x300.png';
import img3 from '../../Images/4237-266x300.png';
import TileAdhesiveModified from './TileAdhesiveModified';
const TileAdhesive = () => {
  const products = [

    {
      "img": img1,
      "name": "LATICRETE 111 CRETE FILLER POWDER",
      "description": "LATICRETE 111 Crete Filler Powder is a factory prepared blend of carefully selected raw materials, portland cement and graded aggregates. It is designed for use with LATICRETE 73 Crete Admix to produce high strength adhesive for fixing all varieties of small to large format tiles and stones. For very large format & thin tiles/stones LATICRETE 4237 Latex admix should be used in place of LATICRETE 73.",
      "applications": "Designed specially for interior & exterior floor & wall installations of all types of tiles & stones.",
      "advantages": [
        "Exceeds ANSI A 118.4 ET & EN 12004/ISO 13007: C2TE S1",
        "High strength formula & ultimate flexibility",
        "Ideal for external facades and underwater applications"
      ],
      "suitableSubstrates": [
        "Concrete", "Cement Mortar Beds", "Ceramic Tiles and Stones",
        "Cement Backer Board", "Concrete Masonry", "Cement Plaster",
        "Brick Masonry", "Cement Terrazzo", "Gypsum Wallboard (interior only)"
      ],
      "coverage": "Approx. 55-60 sq.ft. per 20 kg bag using a ¼” x ¼” (6mmx6mm) notch trowel for a bed of 3mm.",
      "bedThickness": "Can be used upto 12 mm",
      "packaging": "20 kg bag",
      "colour": ["Grey", "White"],
      "compliance": {
        "standards": [
          "ANSI A 118.4 ET", "EN 12004 / ISO 13007: C2TE S1",
          "IS 15477: 2019 : TYPE 3 TS1 ADHESIVE"
        ],
        "VOC": "Practically Nil"
      }
    },
    {
      "img": img2,
      "name": "LATICRETE 73 LATEX CRETE ADMIX",
      "description": "LATICRETE 73 is a Latex Crete Admix mixed with LATICRETE 111 Crete Filler Powder / Adhesives to form tile setting beds. Tile setting adhesives made with LATICRETE 73 Crete Admix provide increased flexibility, stronger bond and compression strength when compared to conventional portland cement mortars. This increased bond strength is especially vital when installing semi and fully vitrified tiles, quarry tiles, pavers and other ceramic floor and wall tiles used in highly critical areas such as external facades, industrial plants, swimming pools and heavy traffic areas. Also provides longer potlife & working time in hot weather conditions.",
      "advantages": [
        "High flexibility",
        "High shock and impact resistance",
        "High compressive strength – Exceeds ANSI A 118.4 ET requirement",
        "Ideal for bonding large format tiles and vitrified tiles"
      ],
      "coverage": "Approx. 55-60 sq.ft. per 5 ltrs., mixed with 20 kg bag of LATICRETE 111 Crete Filler Powder / Adhesive using a ¼” x ¼” (6mm x 6mm) square notch trowel for a bed of 3mm.",
      "packaging": "5 ltr. & 20 ltr pails",
      "notes": "Note: Can be mixed with L 305 / L307 / L 315 Plus to enable better flexibility for external use."
    },
    {
      "img": img1,
      "name": "LATICRETE 73 RAPID CRETE ADMIX",
      "description": "LATICRETE 73 Rapid is a specially designed Latex Crete Admix for use with LATICRETE 111 Crete Filler Powder to make high strength, rapid setting latex fortified thin-set adhesives. Suitable for installation of all types of ceramic and vitrified tiles, industrial tiles and natural stones."
    },
    {
      "img": img3,
      "name": "LATICRETE 4237 HIGH PERFORMANCE LATEX ADMIX",
      "description": "LATICRETE 4237 Latex Admix is a specially designed high performance Latex additive for use with LATICRETE 111 Crete Filler Powder to make high strength thin bed adhesive. It is suitable for installation of all types of ceramic tiles, vitrified tiles, natural stones & agglomerates. It has superior bond strength & flexibility and is also highly resistant to shock & impact. These features make it a premium choice for installation of tile & stone in highly demanding areas such as external facades, industrial floors, high traffic areas etc.",
      "applications": "Designed for installation of all types of ceramic tiles, vitrified tiles, brick and natural stones, LATICRETE 4237 Latex admix offers the speed and economy of adhesives with the permanent, water resistant dependability of portland cement.",
      "advantages": [
        "Superior bond strength",
        "Flexible and shock & impact resistant",
        "Rated for extra heavy traffic conditions",
        "Proven versatility"
      ],
      "coverage": "Approx. 55-60 sq. ft per 5 ltrs, mixed with 20 kg bag LATICRETE 111 Crete Filler Powder / Adhesive using a ¼” x ¼” (6mm x 6mm) square notch trowel for a bed of 3 mm.",
      "bedThickness": "Can be used upto 12 mm",
      "packaging": "20 ltr. pail",
      "compliance": {
        "standards": [
          "ANSI: A118.4 ET", "EN 12004/ISO 13007 : C2TE S1",
          "IS 15477: 2019 : TYPE 3 TS1 ADHESIVE"
        ],
        "VOC": "Very low"
      }
    },
    
  ]

  return (
    <div className="product-page">
      <div className='div_head'>
        <h1>TileAdhesive</h1>
        <p>
          The cracking of tiles or a hollow sound on a tiled floor is caused by the cement and sand mixture which doesn’t form a level base to cover the entire tile surface when fixing them. The perfect solution for this is to use MYK LATICRETE thin-set adhesives.
          MYK LATICRETE Tile Adhesive is a factory made cementitious dry powder which is mixed with water or special liquid additive to form a paste, providing problem-free, full bedding tile/stone installation. MYK LATICRETE thin-set adhesives have high bonding strength, allowing the tiles to directly fix to the substrate.
          Manufactured under stringent quality control procedures, MYK LATICRETE products have been globally proven and professionally endorsed for years.
          Areas of usage: Residential and commercial floors and walls Swimming pools External cladding
        </p>
        <div className='div_headings'>
          <h2>Screed Products</h2>
        </div>
      </div>
      {/* {products.map((product, index) => (
        <div className="product-card" key={index}>
          <div className="product-image">
            <img src={product.img} alt={product.name} />
          </div>
          <h2>{product.name}</h2>
          <p>{product.description}</p>
          <p><strong>Applications:</strong> {product.applications}</p>
          <p><strong>Advantages:</strong></p>
          {product.advantages && (
            <ul>
              {product.advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
          )}
          <p><strong>Suitable Substrates:</strong></p>
          {product.suitableSubstrates && (
            <ul>
              {product.suitableSubstrates.map((substrate, index) => (
                <li key={index}>{substrate}</li>
              ))}
            </ul>
          )}
          <p><strong>Coverage:</strong> {product.coverage}</p>
          {product.bedThickness && (
            <p><strong>Bed Thickness:</strong> {product.bedThickness}</p>
          )}
          <p><strong>Packaging:</strong> {product.packaging}</p>
          {product.colour && (
            <p><strong>Colour:</strong> {product.colour.join(', ')}</p>
          )}
          <p><strong>Compliance Standards:</strong></p>
          {product.compliance && product.compliance.standards && (
            <ul>
              {product.compliance.standards.map((standard, index) => (
                <li key={index}>{standard}</li>
              ))}
            </ul>
          )}
          {product.compliance && product.compliance.VOC && (
            <p><strong>VOC:</strong> {product.compliance.VOC}</p>
          )}
          {product.notes && (
            <p><strong>Notes:</strong> {product.notes}</p>
          )}
        </div>
      ))} */}
      <style>{`
        .product-page {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          gap: 20px;
          padding: 20px;
        }
        .div_head{
          background-color: #f2f2f2;
          text-align: center;
        }
        .div_headings{
          background-color:blue;
          color:white;
        }
        .product-card {
          background-color:#f2f2f2;
          padding: 10px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          width: 47%;
          min-width: 300px;
        }
        
        .product-image {
          overflow: hidden;
          text-align:center;
          width: 50%;
          height: auto;
        }
        .product-image img {
          width:100%;
          height: auto;
          object-fit: cover;
        }
        .product-card h2 {
          margin-top: 0;
        }
        
        .product-card ul {
          margin-top: 5px;
          padding-left: 20px;
        }
        
        .product-card li {
          margin-bottom: 5px;
        }
      `}</style>
   <TileAdhesiveBased/>
   <TileAdhesiveModified/>
    </div>
  );
};

export default TileAdhesive;
