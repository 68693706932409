

import React from 'react';
import img1 from '../../Images/L-345-Super-Flex.png';
import img2 from '../../Images/L-307-MSD-768x889.png';
import img3 from '../../Images/L-305-MSD.png';
import img4 from '../../Images/AAC-1.png';

const TileAdhesiveBased = () => {
    const products = 
        [
            {
              "img": img3,
              "name": "LATAFIX 305 FLOOR AND WALL TILE ADHESIVE",
              "description": "A factory-made bagged cementitious polymer based powder, containing Portland cement and silica sand, it can be mixed with water for installing small and medium size ceramic floor tiles in interior areas using the thinset method of installation.",
              "applications": "Designed for internal floor applications.",
              "advantages": [
                "For fixing ceramic tiles on interior floor & wall",
                "Ideal for new floor tiling",
                "Economical & easy to use",
                "Single component - Just add water"
              ],
              "suitableSubstrates": [
                "Cement Mortar Beds",
                "Cement Plaster",
                "Brick Masonry",
                "Cement Terrazzo"
              ],
              "coverage": "Approx. 55-60 sq. ft. per 20 kg bag with a ¼” x ¼” (6mmx6mm) square notch trowel for a bed of 3mm.",
              "bedThickness": "Can be used upto 12 mm",
              "packaging": ["20 kg and 50 kg* bags"],
              "colour": "Grey",
              "compliance": {
                "standards": [
                  "ANSI: Meets ANSI A 118.1 T",
                  "EN 12004 / ISO 13007: C1T"
                ]
              }
            },
            {
              "img": img2,
              "name": "LATICRETE 307 FLOOR AND WALL TILE ADHESIVE",
              "description": "It is a smooth and creamy polymer based adhesive for installing small to medium format ceramic wall and floor tiles, vitrified tiles, small format natural stones in interior areas. (For improved performance and exterior use, fortify with LATICRETE 73 Crete Admix).",
              "applications": "Designed for internal floor & wall applications.",
              "advantages": [
                "For fixing ceramic tiles on interior floor & wall",
                "Ideal for new tiling",
                "Smooth & creamy",
                "Single component - Just add water"
              ],
              "suitableSubstrates": [
                "Cement Mortar Beds",
                "Existing tiles/stones on floor & wall",
                "Cement plaster",
                "Brick Masonry",
                "Cement Terrazzo"
              ],
              "coverage": "Approx. 55-60 sq. ft. per 20 kg bag with a ¼” x ¼” (6mmx6mm) square notch trowel for a bed of 3mm.",
              "bedThickness": "Can be used upto 12 mm",
              "packaging": ["20 kg and 50 kg* bags"],
              "colour": "Grey & White",
              "compliance": {
                "standards": [
                  "ANSI: A 118.1T",
                  "EN 12004 / ISO 13007: C1T",
                  "IS 15477: 2019: TYPE 1 T ADHESIVE"
                ]
              }
            },
            {
              "img": img4,
              "name": "AAC BLOCK ADHESIVE",
              "description": "It is a factory prepared blend of carefully selected raw materials, Portland cement and graded aggregates and polymers. Designed for use with water to produce high strength thixotropic mortar, for laying aerated light weight concrete, fly ash bricks, cement hollow blocks, cellular concrete blocks or smoothing over the block work surface in layers of up to 12mm thickness, that meet and exceed the requirements of National and International Standards.",
              "applications": "For interior and exterior use for preparing thin bed block mortar for ALC and cellular concrete blocks.",
              "advantages": [
                "Long working time",
                "No curing required after block work is done",
                "Improved adhesion between two blocks",
                "Fast & economical"
              ],
              "suitableSubstrates": [
                "Concrete blocks",
                "Cement mortar blocks/bricks",
                "Concrete hollow blocks",
                "Aerated light weight blocks",
                "Cellular concrete blocks",
                "Fly ash bricks"
              ],
              "coverage": "Approximately 300 sq.ft. per 50 kg bag when used at 2mm bed thickness. Coverage is based on smoothness and evenness of the substrate, size of blocks used and the thickness of mortar used. A general guide line for coverage of mortar is given when used with 25% water added to powder.",
              "packaging": ["20 kg & 50 kg* bags"],
              "colour": "Grey",
              "compliance": {
                "standards": [
                  "ANSI: A 118.1T",
                  "EN: C1TE",
                  "ASTM C 1660",
                  "IS 15477: 2019: TYPE 1 T"
                ],
                "VOC": "Practically Nil"
              }
            },
            {
              "img": img1,
              "name": "LATICRETE 315 PLUS PREMIUM FLOOR AND WALL TILE ADHESIVE",
              "description": "A high performance polymer based adhesive to be mixed with water or latex for installing medium & large format ceramic tiles, vitrified tiles, marble and stone on floors and walls. Mix with LATICRETE 73 Crete Admix for improved performance.",
              "applications": "Designed for interior floor & wall areas.",
              "advantages": [
                "For fixing ceramic, vitrified tiles & natural stone on interior floor & wall",
                "Ideal for tile-on-tile & new floor tiling",
                "High bond strength",
                "Single component - Just add water"
              ],
              "suitableSubstrates": [
                "Concrete",
                "Cement Mortar Beds",
                "Existing tiling on floor & wall",
                "Ceramic Tiles and Stones",
                "Concrete Masonry",
                "Cement Plaster",
                "Brick Masonry",
                "Cement Terrazzo"
              ],
              "coverage": "Approx. 55-60 sq. ft. per 20 kg bag with a ¼” x ¼” (6mmx6mm) square notch trowel for a bed of 3mm.",
              "bedThickness": "Can be used upto 12 mm",
              "packaging": ["20 kg and 50 kg* bags"],
              "colour": "Grey & White",
              "compliance": {
                "standards": [
                  "ANSI: A118.1 T",
                  "EN: 12004 / ISO 13007: C1T",
                  "IS 15477: 2019: TYPE 2 T ADHESIVE"
                ],
                "VOC": "Practically Nil"
              }
            }          
    ]

    return (
        <div className="product-page">
            {products.map((product, index) => (
                <div className="product-card" key={index}>
                    <div className="product-image">
                        <img src={product.img} alt={product.name} />
                    </div>
                    <h2>{product.name}</h2>
                    <p>{product.description}</p>
                    <p><strong>Applications:</strong> {product.applications}</p>
                    <p><strong>Advantages:</strong></p>
                    {product.advantages && (
                        <ul>
                            {product.advantages.map((advantage, index) => (
                                <li key={index}>{advantage}</li>
                            ))}
                        </ul>
                    )}
                    <p><strong>Coverage:</strong> {product.coverage}</p>
                    {product.bedThickness && (
                        <p><strong>Bed Thickness:</strong> {product.bedThickness}</p>
                    )}
                    <p><strong>Packaging:</strong> {product.packaging}</p>
                    <p><strong>Compliance Standards:</strong></p>
                    {product.compliance && product.compliance.standards && (
                        <ul>
                            {product.compliance.standards.map((standard, index) => (
                                <li key={index}>{standard}</li>
                            ))}
                        </ul>
                    )}
                    {product.compliance && product.compliance.VOC && (
                        <p><strong>VOC:</strong> {product.compliance.VOC}</p>
                    )}
                </div>
            ))}
        </div>
    );
};

export default TileAdhesiveBased;