import React from 'react';
import { Row, Col } from 'antd';
import screedhome from '../../Images/screedhome.jpg';
import badMorter from '../../Images/screed1.jpeg';
import screedlogo from '../../Images/screedlogo.jpeg';
import './Landing.css'
function Screed() {
    return (
        <div>
            <h1 style={{ textAlign: 'center', boxShadow: 'rgba(0, 0, 0, 0.07) 0px 1px 2px, rgba(0, 0, 0, 0.07) 0px 2px 4px, rgba(0, 0, 0, 0.07) 0px 4px 8px, rgba(0, 0, 0, 0.07) 0px 8px 16px, rgba(0, 0, 0, 0.07) 0px 16px 32px, rgba(0, 0, 0, 0.07) 0px 32px 64px;' }}>S C R E E D</h1>
            <Row style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}>
                <Col style={{fontSize:'15px'}}>Just imagine. A screed that doesn’t require curing!
                    This breakthrough innovation is the result of MYK LATICRETE’s determined R&D efforts to come up with a solution
                    for the shortcomings of traditional screed.
                    MYK LATICRETE’s new age, polymer enhanced screed is engineered cement which, besides ensuring a level,
                    strong base for perfect tile and stone installation, also offers a host of other benefits.
                </Col>
                <Col span={12} style={{ textAlign: 'center',marginTop:'10px' }}>
                    <img src={screedhome} alt="" />
                </Col>
                <Col span={12} >
                    <div style={{ margin: 'auto', width: '500px', fontSize: '15px' }}>
                        <h2>Advantages</h2>
                        •High quality underlayment for long-lasting tile
                        installation.<br />
                        • Self curing - saves time, costs and enables faster
                        construction<br />
                        • No shrinking and cracking <br />
                        • High strength and workability <br />
                        • External and internal applications<br />
                        • Resistance to environment and temperature
                        variations<br />
                        • Highly durable
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{ textAlign: 'center', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px', fontSize: '15px' }}>
                    <img src={badMorter} alt="" style={{ height: '200px', width: '200px', borderRadius: '50%' }} />
                    <h1>100LATICRETE 226 THICK BED MORTAR</h1>
                    <p>A factory prepared blend of raw materials, portland cement and graded®
                        aggregates, it is designed for use with LATICRETE 3642 Latex Admix and
                        water to produce high strength thick bed adhesive upto 50 mm for installation
                        of all types of ceramic tiles, vitrified tiles & natural stone. The mixture can be
                        used as interior & exterior cement plaster or as screed for floors & walls.
                    </p>
                    <h2>Applications</h2>
                    <p>Interior & exterior floors & wall</p>
                </Col>
                <Col span={12} style={{ boxShadow: 'rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px' }}>
                    <div style={{ margin: 'auto', width: '500px', fontSize: '15px' }}>
                        <h2>Advantages</h2>
                        <p>
                            • Premixed factory blended <br />
                            • Can be applied from 12 mm to 50 mm in one application  <br />
                            • Two component - to be mixed with L3642 latex concentrate <br />
                            • High strength, flexible, shock and impact resistant
                        </p>
                        <h2>Suitable Substrates</h2>
                        <p>Concrete & Cement Mortar Bed </p>
                        <h2>Coverage</h2>
                        <p>Varies by application </p>
                        <h2>Packaging</h2>
                        <p>50 kg bag </p>
                        <h2>Colour</h2>
                        <p>Grey & White</p>
                    </div>
                </Col>
            </Row><br />
            <div style={{ backgroundColor: 'black' }}>
                <Row>
                    <Col style={{ background: 'linear-gradient(to right,purple,#7d26d4,#ab1ab0)', height: '400px', width: '400px', textAlign: 'center', color: 'white', margin: 'auto', borderRadius: '50px' }}>
                        <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%', marginTop: '80px' }} />
                        <h2 >Specification Sheet</h2>
                        <p>High quality underlayment for long-lasting tile
                            installation.</p>
                    </Col>
                    <Col style={{ background: 'linear-gradient(to right,purple,#7d26d4,#ab1ab0)', height: '400px', width: '400px', textAlign: 'center', color: 'white', margin: 'auto', borderRadius: '50px' }}>
                        <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%', marginTop: '80px' }} />
                        <h2 >Specification Sheet</h2>
                        <p>High quality underlayment for long-lasting tile
                            installation.</p>
                    </Col>
                    <Col style={{ background: 'linear-gradient(to right,purple,#7d26d4,#ab1ab0)', height: '400px', width: '400px', textAlign: 'center', color: 'white', margin: 'auto', borderRadius: '50px' }}>
                        <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%', marginTop: '80px' }} />
                        <h2 >Specification Sheet</h2>
                        <p>High quality underlayment for long-lasting tile
                            installation.</p>
                    </Col>

                </Row><br/>
                <Row>
                    <Col style={{ background: 'linear-gradient(to right,purple,#7d26d4,#ab1ab0)', height: '400px', width: '400px', textAlign: 'center', color: 'white', margin: 'auto', borderRadius: '50px' }}>
                        <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%', marginTop: '80px' }} />
                        <h2 >Specification Sheet</h2>
                        <p>High quality underlayment for long-lasting tile
                            installation.</p>
                    </Col>
                    <Col style={{ background: 'linear-gradient(to right,purple,#7d26d4,#ab1ab0)', height: '400px', width: '400px', textAlign: 'center', color: 'white', margin: 'auto', borderRadius: '50px' }}>
                        <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%', marginTop: '80px' }} />
                        <h2 >Specification Sheet</h2>
                        <p>High quality underlayment for long-lasting tile
                            installation.</p>
                    </Col>
                    <Col style={{ background: 'linear-gradient(to right,purple,#7d26d4,#ab1ab0)', height: '400px', width: '400px', textAlign: 'center', color: 'white', margin: 'auto', borderRadius: '50px' }}>
                        <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%', marginTop: '80px' }} />
                        <h2 >Specification Sheet</h2>
                        <p>High quality underlayment for long-lasting tile
                            installation.</p>
                    </Col>

                </Row>
            </div>
            <br /><br />
        </div>
    )
}

export default Screed;

