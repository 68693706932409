import React from 'react';
import './Landing.css';
import { Carousel, Card } from 'antd';
import slider2 from '../../Images/slider2.jpeg'
import slider3 from '../../Images/slider3.jpeg'
import slider4 from '../../Images/slider4.jpeg'
import Categories from './Categories';
import Myk from './Myk';
import icon from '../../Images/logo.jpeg';
import { FloatingWhatsApp } from 'react-floating-whatsapp2';
import { useLocation } from 'react-router-dom';
import Tenex from '../Tenex/Tenex';
import KeraKoll from '../KeraKoll';
import Akemi from '../../Akemi';
import Certificate1 from '../../Images/certificate4.jpeg'
import Certificate3 from '../../Images/certificate3.jpg'
import Certificate4 from '../../Images/Certificate2.jpg'
import Certificate5 from '../../Images/certificate1.jpg'
import SlideLogo from '../../Images/krishnalogo.jpeg';
import MainLanding from '../MainLading'
import CustomSlides from './CustomSlide';

function Landing() {
    const location = useLocation();
    const isMobile = window.innerWidth <= 768;
    return (
        <>
            <MainLanding />
            <div className='Landing_main'>
                {isMobile ? (
                    <Carousel autoplay>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={Certificate1} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={Certificate3} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={Certificate5} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={Certificate4} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={SlideLogo} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={slider2} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={slider3} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div>
                                    <img src={slider4} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                    </Carousel>
                ) : (
                    <Carousel autoplay>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div style={{ display: 'flex' }}>
                                    <img src={Certificate1} alt="" height="500" width="100%" />
                                    <img src={Certificate3} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div style={{ display: 'flex' }}>
                                    <img src={Certificate5} alt="" height="500" width="100%" />
                                    <img src={Certificate4} alt="" height="500" width="100%" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div style={{ display: 'flex' }}>
                                    <img src={slider3} alt="" height="500" width="700px" />
                                    <img src={slider4} alt="" height="500" width="700px" />
                                </div>
                            </Card>
                        </div>
                        <div className="carousel-slide-flex">
                            <Card>
                                <div style={{ display: 'flex',columnGap:'10px' }}>
                                    <img src={SlideLogo} alt="" height="500" width="700px" />
                                    <img src={slider2} alt="" height="500" width="700px" />
                                </div>
                            </Card>
                        </div>
                    </Carousel>
                )}
                <div style={{ width: '90%', margin: 'auto' }}>
                    <CustomSlides />
                </div>
                <br />
                <Categories />
                <br />

                {/* Corrected conditional rendering using ternary operator */}
                {location.state && location.state.category === 'MYKLATICRETE' && <Myk />}
                {location.state && location.state.category === 'Tenex' && <Tenex />}
                {location.state && location.state.category === 'Kera Koll' && <KeraKoll />}
                {location.state && location.state.category === 'Akemi' && <Akemi />}
                <FloatingWhatsApp notificationSound={true} phoneNumber='919039643040' accountName='Shree Krishna Power Tools' avatar={icon} />
            </div>
        </>
    );
}

export default Landing;
