import React, { useState } from 'react';
import './Header.css';
import YashviLogo from '../../Images/logo.jpeg';
import { Input } from 'antd';
import { Link } from 'react-router-dom';
import CompanyCategory from '../CompanyCtaegory'; // Corrected typo in import

const Header = () => {
    const { Search } = Input;
    const [categoryList, setCategoryList] =useState(null)
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <div>
            <header className="header">
                <div className="logo">
                   <Link to="/" onClick={()=>{setCategoryList(null); window.scroll(0,0)}}> <img src={YashviLogo} width={120} height={50} alt="Logo" /></Link>
                </div>
                <div className={`menu ${isDrawerOpen ? 'open' : ''}`}>
                    <div className="menu-toggle" onClick={toggleDrawer}>
                        {isDrawerOpen ? '✖' : '☰'}
                    </div>
                    <nav className={`nav ${isDrawerOpen ? 'active' : ''}`}>
                        <ul className='header_ul'>
                            <li><CompanyCategory categoryList={categoryList} setCategoryList={setCategoryList} toggleDrawer={toggleDrawer}/></li> {/* Corrected component name */}
                            <li><Link onClick={toggleDrawer} to="/">HOME</Link></li>
                            <li><a onClick={toggleDrawer} href='https://myklaticrete.com/' target='_blank' rel="noopener noreferrer">MYKLATICRETE</a></li> {/* Corrected link */}
                            <li><a onClick={toggleDrawer} target='_blank' href='https://www-roff-in.translate.goog/tenax/?_x_tr_sl=en&_x_tr_tl=hi&_x_tr_hl=hi&_x_tr_pto=tc'>TENEX</a></li>
                            <li><a onClick={toggleDrawer} href='https://products.kerakoll.com/en-IN' target='_blank' rel="noopener noreferrer">KERAKOLL</a></li> {/* Corrected link */}
                            <li><a onClick={toggleDrawer} target='_blank' href='https://www.akemi.in/'>AKEMI</a></li>
                            <li><Link onClick={toggleDrawer} to='/contact'>CONTACT</Link></li> {/* Corrected path */}
                        </ul>
                    </nav>
                </div>
            </header>
        </div>
    );
};

export default Header;
