import React from 'react';
import './KeraKoll.css';
// import KRISHNAiMAGE from '../Images/kpt.jpeg';
// import TENEX from '../Images/img1.jpeg';
// import ACEME from '../Images/im2.jpeg';
// import KERAKOLL from '../Images/img3.jpeg';
// import MYK from '../Images/img4.jpeg';
import LOCATION from '../Images/location.jpeg';
import MAIL from '../Images/emailimg.jpeg';
import Krishna from '../Images/removebg.png'
import mayur from '../Images/mayur.png'
function MainLading() {
    return (
        <div className='main'>
            <div className='head'>
                <img className='logo' src={mayur} alt="KPT" />
                <div className='contact'>
                    <p className='name'>MOHAN KUMAWAT</p>
                    <p className='phone'>90396 43040</p>
                    <p className='phone'>90090 62861</p>
                </div>
            </div>
            <div style={{textAlign:'center'}}>
            <img src={Krishna} style={{height:'200px'}}/>
            <p className='distributors'>Authorised Distributors of:</p>
            </div>
          
            <hr className='line' />
            <div className='brands'>
                {/* <img className='brand' src={TENEX} alt="img1" /> */}
                <h2>TENAX</h2>
                <h2>AKEMI</h2>
                <h2>KERAKOLL</h2>
                <h2>MYKLATICRETE</h2>
                {/* <img className='brand' src={ACEME} alt="img2" />
                <img className='brand' src={KERAKOLL} alt="img3" />
                <img className='brand' src={MYK} alt="img4" /> */}
            </div>
            <div className='footer'>
                <div className='location'>
                    <img className='icon' src={LOCATION} alt="locationimg" />
                    <span className='address'>Ring Road No.1 Near Kamla Woman Hospital, Chhattisgarh Nagar, Raipur (C.G.)</span>
                </div>
                <div className='email'>
                    <img className='icon' src={MAIL} alt="emailimg" />
                    <span>krishnapower1981@gmail.com</span>
                </div>
            </div>
        </div>
    );
}

export default MainLading;