import React from 'react';
import './Footer.css'
function Footer() {
  return (
    <div className='Footer_content'>
      <h4>Powered By Yashvitech IT Solution Pvt. Ltd.</h4>
    </div>
  )
}

export default Footer
