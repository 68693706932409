import React, { useState, useRef } from 'react';
import { FaMapMarkerAlt, FaFacebook, FaInstagramSquare, FaLinkedin, FaTwitter, FaYoutube } from "react-icons/fa";
import { Card, Modal, Button } from 'antd';
import emailjs from 'emailjs-com';
import './Contact.css'; // Import CSS file for custom styling

export default function Contact() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState('');
    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm('service_u194qg8', 'template_8um77za', form.current, 'xuveV9AdQESdYCXqK')
            .then((result) => {
                if (result.text === 'OK') {
                    setIsModalOpen(true);
                    form.current.reset();
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    return (
        <div id='contact' data-aos="fade-up">
            <Modal
                visible={isModalOpen}
                footer={null}
                closable={false}
                width={400}
                style={{ textAlign: "center" }}
                onCancel={handleCancel}
            >
                <h1>Thank You {name} From Krishna Power Tool</h1>
                <h1>We Will Contact You Very Soon</h1>
                <Button onClick={handleCancel} style={{ marginTop: "10px", color: "white", backgroundColor: "#6A5ACD" }}>Ok</Button>
            </Modal>
            <div className='m-auto text-[30px] font-semibold my-3 text-center' style={{fontSize:'20px',textAlign:'center',marginTop:'10px'}}>Contact To Krishna Power tool</div>
            <div className='flex sm:flex-row flex-col'>

                <div className='sm:w-[50%] w-[100%]'>
                    <div className="max-w-md mx-auto mt-8 text-start">
                        <form ref={form} onSubmit={sendEmail} className="custom-form">
                            <div className="input-group">
                                <label htmlFor="name">Name</label>
                                <input
                                    id="name"
                                    type="text"
                                    placeholder="Name"
                                    name="user_name"
                                    required
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="company">Company Name</label>
                                <input
                                    id="company"
                                    type="text"
                                    placeholder="Company Name"
                                    name="company_name"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="mobile">Mobile</label>
                                <input
                                    id="mobile"
                                    type="text"
                                    placeholder="Mobile"
                                    name="mobile_no"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="email">Email</label>
                                <input
                                    type="email"
                                    name="user_email"
                                    placeholder="Email address"
                                    required
                                />
                            </div>
                            <div className="input-group">
                                <label htmlFor="description">Message</label>
                                <textarea
                                    id="description"
                                    placeholder="Description"
                                    name="message"
                                    required
                                />
                            </div>
                            <div className="submit-btn">
                                <button type="submit">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>

                {/* <div className='sm:w-[50%] w-[100%]'>
                    <div className="max-w-md mx-auto mt-8 text-start">
                        <h2 className="text-center"><b>OFFICE</b></h2>
                        <br />
                        <h3><FaMapMarkerAlt className="location-icon" /></h3>
                        <h3>1st Floor, Front of Union Bank, Lily Chowk, Puranibasti Raipur (C.G),</h3>
                        <h3>info@yashvitech.com, 8223079476, 8319308575</h3>
                        <div className="social-links">
                            <Card><a href="https://www.facebook.com/profile.php?id=100094370628556" target="_blank"><FaFacebook className="social-icon" /></a></Card>
                            <Card><a href="https://www.instagram.com/yashvitechraipur/" target="_blank"><FaInstagramSquare className="social-icon" /></a></Card>
                            <Card><a href="https://www.linkedin.com/in/yashvitech-it-solution-b10b1a282/" target="_blank"><FaLinkedin className="social-icon" /></a></Card>
                            <Card><FaTwitter className="social-icon" /></Card>
                            <Card><a href="https://youtube.com/@YashvitechITSolution?si=iRydMOEileM4odLQ" target="_blank"><FaYoutube className="social-icon" /></a></Card>
                        </div>
                    </div>
                </div> */}
            </div>
        </div>
    );
}
