import React from 'react';
import img1 from '../../Images/waterproofing2.png';
import img2 from '../../Images/waterproofing1.png';

const WaterProofing = () => {
  const products = [
    {
      "img": img2,
      "name": "LATICRETE Hydro Ban",
      "description": "LATICRETE Hydro Ban is a thin, load bearing waterproofing/crack isolation membrane that does not require the use of fabric in the field, coves or corners. It is a single component self curing liquid rubber polymer that forms a flexible, seamless waterproofing membrane. LATICRETE Hydro Ban bonds directly to a wide variety of substrates.",
      "applications": [
        "Interior and exterior",
        "Swimming pools, fountains and water bodies",
        "Shower pans, stalls and tub surrounds",
        "Industrial, commercial and residential bathrooms and laundries",
        "Spas and hot tubs",
        "Kitchens and food processing areas",
        "Terraces and balconies over unoccupied spaces",
        "Countertops and facades",
        "Steam rooms (when used in conjunction with vapour barrier)"
      ],
      "advantages": [
        "Single component",
        "No fabric required for joints upto 3mm",
        "Bonds directly to copper, steel & PVC plumbing fixtures",
        "Fast drying, allows for flood test in 72 hours"
      ],
      "suitableSubstrates": [
        "Concrete & brick masonry",
        "Cement mortar beds",
        "Cement plaster",
        "Gypsum wallboard*",
        "Exterior glue plywood*",
        "Ceramic tile & stone**",
        "Cement terrazzo**",
        "Cement backer board***"
      ],
      "coverage": "Approx. 225-250 sq. ft. per 20 ltr pail",
      "packaging": "20 ltr pail",
      "compliance": {
        "standards": [
          "ANSI: A118.12",
          "ANSI: A118.10, ETAG: 022"
        ],
        "VOC": "Very low"
      },
      "notes": "*Interior applications only.\n**If skim coated with LATICRETE Latex Thin-set Mortar\n***Consult cement backer board manufacturer for specific installation recommendations and to verify acceptability for exterior use."
    },
    {
      "img": img1,
      "name": "LATICRETE 9237 Waterproofing Membrane",
      "description": "It is a roller applied, liquid waterproofing membrane for direct use under thin and thick-bed installations of ceramic tile, stone and other finishes. It is recommended for residential and commercial floor and wall installations.",
      "applications": [
        "Kitchens, bathrooms, swimming pools, terrace gardens etc."
      ],
      "advantages": [
        "Fast and easy to install – applied like paint",
        "Water cleanable when fresh",
        "Non-flammable",
        "Proven history"
      ],
      "suitableSubstrates": [
        "Concrete & brick masonry",
        "Cement mortar beds",
        "Cement plaster",
        "Gypsum wallboard",
        "Exterior grade plywood",
        "Ceramic tile & stone",
        "Cement terrazzo",
        "Cement backer board"
      ],
      "coverage": "Approx. 200-225 sq. ft. per 20 ltr pail",
      "packaging": "5 ltr & 20 ltr pails",
      "compliance": {
        "standards": [
          "EN 14891",
          "ANSI: A118.10"
        ],
        "VOC": "Very low"
      },
      "notes": "Note: Membrane reinforcing fabric for coves, corners and broad joints is sold separately.\nFabric roll packing: width - 15 cm and length - 18 m to 25 m"
    }
  ];

  return (
    <div className="product-page">
      <div className='div_head'>
        <h1>Waterproofing</h1>
        <p>
          Water-Proofing forms a barrier designed to prevent water entering or escaping certain sections of building structures, allowing the vapour to travel through. If not done correctly it may cause immediate or long term damage, even affecting the structure itself.
          Areas that commonly require Water-Proofing:
          Internal: Bathrooms, Kitchens, Dining Rooms, Shower Recesses, Laundries, Sauna, Steam Rooms.
          External: Terraces, Roofs, Podium Slabs, Balconies, Retaining Walls, Plunge Pools, Swimming Pools.
        </p>
        <div className='div_headings'>
          <h2>Screed Products</h2>
        </div>
      </div>
      {products.map((product, index) => (
        <div className="product-card" key={index}>
          <div className="product-image">
            <img src={product.img} alt={product.name} />
          </div>
          <div className="product-details">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <p><strong>Applications:</strong></p>
            <ul>
              {product.applications.map((application, index) => (
                <li key={index}>{application}</li>
              ))}
            </ul>
            <p><strong>Advantages:</strong></p>
            <ul>
              {product.advantages.map((advantage, index) => (
                <li key={index}>{advantage}</li>
              ))}
            </ul>
            <p><strong>Suitable Substrates:</strong></p>
            <ul>
              {product.suitableSubstrates.map((substrate, index) => (
                <li key={index}>{substrate}</li>
              ))}
            </ul>
            <p><strong>Coverage:</strong> {product.coverage}</p>
            <p><strong>Packaging:</strong> {product.packaging}</p>
            <p><strong>Compliance Standards:</strong></p>
            <ul>
              {product.compliance.standards.map((standard, index) => (
                <li key={index}>{standard}</li>
              ))}
            </ul>
            <p><strong>VOC:</strong> {product.compliance.VOC}</p>
            {product.notes && (
              <p><strong>Notes:</strong> {product.notes}</p>
            )}
          </div>
        </div>
      ))}
      <style>{`
        .product-page {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          gap: 20px;
          padding: 20px;
        }
        .div_head{
          background-color: #f2f2f2;
          text-align: center;
        }
        .div_headings{
          background-color:blue;
          color:white;
        }
        .product-card {
          display: flex;
          flex-wrap: wrap;
          color: black;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          width: 45%;
          min-width: 300px;
        }
        .product-image {
          overflow: hidden;
          text-align:center;
          width: 50%;
          height: auto;
        }
        .product-image img {
          width:100%;
          height: auto;
          object-fit: cover;
        }
        .product-details {
          width: 50%;
          padding-left: 20px;
        }
        .product-card h2 {
          margin-top: 0;
        }
        .product-card ul {
          margin-top: 5px;
          padding-left: 20px;
        }
        .product-card li {
          margin-bottom: 5px;
        }
      `}</style>
    </div>
  );
};

export default WaterProofing;
