import React from 'react';
import img from '../../Images/MYKL-Superset-IWP-191x300.png';
const CementAdditive = () => {
    const cementAdditive = {
        "img": img,
        "name": "SUPER SET IWP",
        "description": "SUPER SET IWP is a Single Component, specially designed with Polymers & Additives to minimize shrinkage and reduce water permeability of cement concrete, plaster and mortar.",
        "applications": [
            "Roof concrete",
            "Screeds",
            "Water tanks & Water retaining structures",
            "Cement based Plasters",
            "Bathrooms and balconies",
            "Sumps and drains"
        ],
        "advantages": [
            "Reduces the water Permeability into cement concrete",
            "Reduce the water demand of cement mixes",
            "Increase the waterproofing properties"
        ],
        "dosage": "200ml per 50 kg bag of Cement",
        "packaging": ["200ml", "1L bottles", "5L can", "20L pail"],
        "compliance": "Meets the following standards: IS 2645:2003"
    };

    return (
        <div className="cement-additive">
            <div className="cement-additive-card">
                <div className="product-image">
                    <img src={cementAdditive.img} alt={cementAdditive.name} />
                </div>
                <h2>{cementAdditive.name}</h2>
                <p>{cementAdditive.description}</p>
                <p><strong>Applications:</strong> {cementAdditive.applications.join(', ')}</p>
                <p><strong>Advantages:</strong></p>
                <ul>
                    {cementAdditive.advantages.map((advantage, index) => (
                        <li key={index}>{advantage}</li>
                    ))}
                </ul>
                <p><strong>Dosage:</strong> {cementAdditive.dosage}</p>
                <p><strong>Packaging:</strong> {cementAdditive.packaging.join(', ')}</p>
                <p><strong>Compliance:</strong> {cementAdditive.compliance}</p>
            </div>
            <style jsx>{`
                .cement-additive {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 100vh;
                    margin-top:5%;
                }
                
                .cement-additive-card {
                    background-color: #f2f2f2;
                    color:black;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    width: 60%;
                    max-width: 600px;
                }
                    .product-image {
                    overflow: hidden;
                    text-align:center;
                    width: 100%;
                    height: auto;
                    }

                    .product-image img {
                    width:30%;
                    height: auto;
                    object-fit: cover;
                    }
                
                .cement-additive-card h2 {
                    margin-top: 0;
                }
                
                .cement-additive-card ul {
                    margin-top: 5px;
                    padding-left: 20px;
                }
                
                .cement-additive-card li {
                    margin-bottom: 5px;
                }
            `}</style>
        </div>
    );
};

export default CementAdditive;