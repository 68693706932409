import React, { useState } from 'react';
import { Button, Input, Modal } from 'antd';
import logo from '../../Images/logo.jpeg';
const App = () => {
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const showModal = () => {
    setOpen(true);
  };
  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 3000);
  };
  const handleCancel = () => {
    setOpen(false);
  };
  return (
    <>
      <Button  onClick={showModal} danger>
        Get Latest Price
      </Button>
      <Modal
        open={open}
        style={{ textAlign: 'center', }}
        title={<h2>Get Latest Price</h2>}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
        ]}
      >
        <form style={{ textAlign: 'center' }}>
          <img src={logo} alt="" height="150"/><br/>
         <i style={{fontSize:'20px'}}><b>Mobile No. : </b> <b>9039643040</b></i> 
        </form>
      </Modal>
    </>
  );
};
export default App;