// import React from 'react';
// import { Col, Row, Card } from 'antd';
// import DetailsgetModal from './DetailsgetModal';
// import { useLocation } from 'react-router-dom';

// function DetailsPage() {
//     const state = useLocation().state;

//     return (
//         <div>
//             <Row>
//                 <Col span={8} style={{ textAlign: 'center' }}>
//                     <Card style={{height:'500px'}}>
//                         <img src={state.img} alt="" height={400}/><br />
//                         <DetailsgetModal />
//                     </Card>
//                 </Col>
//                 <Col span={7} >
//                     <Card style={{height:'500px'}}>
//                         <h1>{state.name}</h1>
//                         <p>{state.price}</p>
//                         <p>Coverage: {state.coverage}</p>
//                         <p>Packaging Size: {state.packagingSize}</p>
//                         <p>Packaging Type: {state.packagingType}</p><br />
//                         <p>{state.description}</p>
//                     </Card>
//                 </Col>
//                 <Col span={8} style={{ fontSize: '300'}}>
//                     <Card style={{height:'500px'}}>
//                         <h2>Advantages</h2>
//                         <ul>
//                             {state.advantages.map((advantage, index) => (
//                                 <li key={index}>{advantage}</li>
//                             ))}
//                         </ul>
//                         <h2>Standards</h2>
//                         <ul>
//                             {state.compliance.standards.map((standard, index) => (
//                                 <li key={index}>{standard}</li>
//                             ))}
//                         </ul>
//                     </Card>
//                 </Col>
//             </Row>
//         </div>
//     )
// }

// export default DetailsPage;
import React from 'react'
import { useLocation } from 'react-router-dom';
import Tenex from '../Tenex/Tenex';
import KeraKoll from '../KeraKoll';
import Akemi from '../../Akemi';
import Myk from '../Home/Myk';

function DetailsPage() {
    const location = useLocation();
    return (
        <div>
            {location.state && location.state.category === 'MYKLATICRETE' && <Myk />}
            {location.state && location.state.category === 'Tenex' && <Tenex />}
            {location.state && location.state.category === 'Kera Koll' && <KeraKoll />}
            {location.state && location.state.category === 'Akemi' && <Akemi />}
        </div>
    )
}

export default DetailsPage
