// import { Col, Row } from 'antd';
// import React from 'react';
// import { Link } from 'react-router-dom';
// import badMorter from '../../Images/screed1.jpeg';
// import screedlogo from '../../Images/screedlogo.jpeg';
// function Page() {
//     return (
//         <div>
//             <h1 style={{ textAlign: 'center' }}>Our Services</h1>
//             <br />
//             <Row>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}>
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>SCREED</h2>
//                         <p>Just imagine. A screed that doesn’t require curing!
//                             This breakthrough innovation is the result of MYK
//                             LATICRETE’s determined R&D efforts to come up with a
//                             solution for the shortcomings of traditional screed.
//                             MYK LATICRETE’s new age, polymer enhanced screed
//                             is engineered cement which besides ensuring a level,
//                             strong base for perfect tile and stone installation, offers
//                             a host of benefits</p>
//                         <br />
//                         <Link to="./screed-main"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>
//                 </Col>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}  >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>WATERPROOFING</h2>
//                         <p>What happens when the substrate you have waterproofed
//                             develops a crack? Water seepage, right? Wrong!
//                             MYK LATICRETE has innovated in this critical area to
//                             bring you a game-changing product. The advanced
//                             single component waterproofing membrane has the
//                             unique ability to bridge cracks in the substrate (up to 3
//                             mm), and requires no other additional accessories for
//                             exceptional performance. </p>
//                         <br />
//                         <Link to="./water-proofing"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24} >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>TILE ADHESIVES</h2>
//                         <p>Across the world, the winds of change are sweeping
//                             away traditional methods of installing tiles and stones as
//                             these have proven to be incapable of handling modern
//                             products and materials.
//                             Rising to meet the challenge is MYK LATICRETE’s
//                             range of new age polymer tile adhesives. Far superior
//                             to conventional methods, these tile adhesives break the
//                             shackles imposed on your creativity by allowing you to
//                             experiment with aesthetics.</p>
//                         <br />
//                         <Link to="./tile-addisive"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//             </Row>
//             <Row style={{ margin: '10px auto' }}>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}  >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>GROUTS</h2>
//                         <p>Not using grouts when laying tiles can lead to problems
//                             ranging from chipped corners, water seepage, staining,
//                             to even breeding of fungus and the tiles debonding or
//                             falling off.
//                             Extensive studies and in-depth research by some of the
//                             most innovative minds in the business have seen the
//                             emergence of a new breed of grout that makes all others
//                             obsolete.</p>
//                         <br />
//                         <Link to="./groot"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}  >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>STONE CARE PRODUCTS</h2>
//                         <p>Just like how a refreshing spa experience rejuvenates,
//                             revives and helps us look our best, stone and tile floors
//                             too, thrive when lavished with such care.
//                             MYK LATICRETE presents a world class range of innovative
//                             products - Sealers, Enhancers, Cleaners - that augment the
//                             appearance of interiors and exteriors while adding years
//                             to the life of stone and tiles. </p>
//                         <br />
//                         <Link to="./stone-care"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}  >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>LATAFINISH WALL PUTTY</h2>
//                         <p>LATAFINISH Wall Putty from MYK LATICRETE not only strengthens walls but also protects expensive paint from
//                             dampness and moisture. Vastly superior to any other wall putty available in the market today, it serves as the best
//                             sub-surface for all types of interior and exterior paint applications. Its greater adhesive properties enable perfect
//                             .</p>
//                         <br />
//                         <Link to="./wall-putty"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//             </Row>
//             <Row style={{ margin: '10px auto' }}>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24} >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>CEMENT ADDITIVE</h2>
//                         <p>Cement additives are materials added to cement for the optimization
//                             of the cement properties and the cement grinding process.
//                             Cement additives are classified into different product groups
//                             such as grinding aids, strength enhancers and performance enhancers.</p>
//                         <br />
//                         <br />
//                         <Link to="./cement-aditive"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}>
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>3D PRINTING MORTAR</h2>
//                         <p>As the world moves towards a more sustainable future, it is becoming increasingly
//                             important to consider eco-friendly methods of construction. One such method of
//                             construction is 3D concrete printing, which has the potential to save tremendous
//                             amounts of energy and reduce the need for new construction materialsand products.</p>
//                         <br />
//                         <Link to="./printing"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//                 <Col style={{ margin: '10px auto', position: 'relative', height: '300px', width: "400px" }} xl={7} xs={24}  >
//                     <img src={badMorter} alt="" style={{ position: 'absolute', left: 0, height: '300px', width: "400px" }} />
//                     <div style={{ position: 'absolute', width: '400px', textAlign: "center", backgroundColor: "black", height: '300px', color: 'white', opacity: '0.8' }} className='screed_div'>
//                         <br />
//                         <img src={screedlogo} alt="" style={{ height: '50px', borderRadius: '50%' }} />
//                         <h2>SOUND CONTROL</h2>
//                         <p>Choose from MYK LATICRETE sound control flooring solutions, for use in both residential and commercial
//                             settings. MYK LATICRETE offers a line of professional grade sound control products including underlay and
//                             overlay system that provides noise reduction as well as vibration isolation. In addition, MYK LATICRETE sound
//                             control flooring products achieve low ∆IIC rating.</p>
//                         <br />
//                         <Link to="./sound-control"><button style={{ width: '100px', height: '30px', backgroundColor: 'black', color: 'white' }}>Learn More</button></Link>
//                     </div>

//                 </Col>
//             </Row><br />
//         </div>
//     )
// }

// export default Page;

// import React, { useEffect, useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import home from './assets/home.pdf'

// // Enable PDF.js worker
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const PDFThumbnailViewer = () => {
//     const [file, setFile] = useState(null);
//     const [numPages, setNumPages] = useState(null);

//     useEffect(() => {
//         getPdf()
//     }, [])

//     const getPdf = () => {
//         setFile(home)
//     }
//     const onDocumentLoadSuccess = ({ numPages }) => {
//         setNumPages(numPages);
//     };

//     return (
//         <div>
//             {file && (
//                 <div style={{ display: 'inline-block' }}>
//                     <Document file={file} onLoadSuccess={onDocumentLoadSuccess} >
//                         {Array.from(new Array(numPages), (el, index) => (
//                             <Page key={`page_${index + 1}`} pageNumber={index + 1} width={900}/>
//                         ))}
//                     </Document>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default PDFThumbnailViewer;


import React, { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import home from '../../Images/myk.pdf';
// import './About.css'; // Import CSS for styling

// Enable PDF.js worker
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFThumbnailViewer = () => {
    const [file, setFile] = useState(null);
    const [numPages, setNumPages] = useState(null);
    const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleResize = () => {
        setViewportWidth(window.innerWidth);
    };

    useEffect(() => {
        getPdf();
    }, []);

    const getPdf = () => {
        setFile(home);
    };

    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    };

    const calculatePageWidth = () => {
        // Adjust page width dynamically based on viewport width
        const maxWidth = 0.9 * viewportWidth; // Adjust as needed
        const calculatedWidth = Math.min(maxWidth, 900); // Set maximum width
        return calculatedWidth;
    };

    return (
        <div className="pdf-viewer-container"> {/* Apply styling for full-screen */}
            {file && (
                <div className="pdf-document-container"> {/* Apply styling for PDF document */}
                    <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
                        {Array.from(new Array(numPages), (el, index) => (
                            <Page key={`page_${index + 1}`} pageNumber={index + 1} width={calculatePageWidth()} />
                        ))}
                    </Document>
                </div>
            )}
        </div>
    );
};

export default PDFThumbnailViewer;
