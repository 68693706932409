
// import React from 'react';
// import './Tenex.css';
// import Tenexmore from './Tenexmore';
// import img from '../../Images/270-1.png';
// function App() {
//   const productsData = {
//     "products": [
//       {
//         "name": "TOP SEALER",
//         "description": "TENAX Skudo Universal Plus",
//         "Field_of_Application": "A water-based water and oil proof anti-stain protector. It penetrates deeper into the micro-porosities & prevents liquids from penetrating, Permeable to vapours and letting materials breath.",
//         "FEATURES": [
//           "Gives natural look",
//           "Food grade approves",
//           "Indoor & outdoor",
//           "Quick drying, allows application on not fully dried substrates"
//         ],
//         "PACKAGING_UNITS_SHELF_LIFE": [
//           { "Size": "1 Ltr", "Shelf_Life": "12 months" }
//         ],
//         "COLOUR": "Colorless"
//       },
//       {
//         "name": "TOP SEALER",
//         "description": "TENAX Proseal",
//         "Field_of_Application": "Solvent-based oil proof and water proof penetrating sealer for protecting in depth granite marble, limestone, sandstone and terracotta. Generally recommended for quartz-based materials and materials with mixed presence of silicates and carbonates.",
//         "FEATURES": [
//           "Recommended for indoor and outdoor application",
//           "Performs well on polished, honed, sandblast and flamed surfaces",
//           "Gives a coverage of 20-30 sqm/L"
//         ],
//         "PACKAGING_UNITS_SHELF_LIFE": [
//           { "Size": "1 Ltr", "Shelf_Life": "12 months" }
//         ],
//         "COLOUR": "Colorless"
//       },
//       {
//         "name": "TOP SEALER",
//         "description": "TENAX Hydrex",
//         "Field_of_Application": "An anti-stain which block microporosities so that prevents liquids from penetrating inside stone. It protects from water based stains.",
//         "FEATURES": [
//           "Gives natural look",
//           "Indoor & outdoor",
//           "Suitable for polished marbles like: Carrara, Thassos, Trani, Botticino, Marfill and similar",
//           "Permeable to vapors letting materials breath"
//         ],
//         "PACKAGING_UNITS_SHELF_LIFE": [
//           { "Size": "1 Ltr", "Shelf_Life": "24 months" }
//         ],
//         "COLOUR": "Transparent"
//       },
//       {
//         "name": "TOP SEALER + ENHANCER",
//         "description": "TENAX Ager",
//         "Field_of_Application": "For enhancing colour / giving a wet look on stones and previnting from oil & water based stains. Suitable for surfaces which are polished, honed, sanded, brushed, flamed or rough.",
//         "FEATURES": [
//           "Colour enhancer & top sealer",
//           "For both for indoor and outdoor applications",
//           "Gives protection to the surfaces against oil and water",
//           "Gives anti-graffiti (anti-scratch) effect",
//           "Gives a coverage of 20-30 sqm/L"
//         ],
//         "PACKAGING_UNITS_SHELF_LIFE": [
//           { "Size": "1 Ltr", "Shelf_Life": "24 months" }
//         ],
//         "COLOUR": "Colorless"
//       },
//       {
//         "name": "CLEANER",
//         "description": "TENAX Bravo Sgrassatore",
//         "Field_of_Application": "Removes greasy, synthetic & extraordinary organic stains/ dirt like – oil, fats, soap, paint, varnish, ink etc. from acid sensitive stones – like non-glossy marble, limestone etc.",
//         "FEATURES": [
//           "Concentrated product to be diluted in water",
//           "Water base",
//           "Ordinary and extraordinary maintenance",
//           "Alkaline nature"
//         ],
//         "PACKAGING_UNITS_SHELF_LIFE": [
//           { "Size": "1 Ltr", "Shelf_Life": "24 months" }
//         ],
//         "COLOUR": "Orange Liquid"
//       },
//       {
//         "name": "CLEANER",
//         "description": "TENAX Bravo Disincrostante",
//         "Field_of_Application": "Concentrated detergent, suitable for the removal of dirt from the building site such as joint residues, rust, traces of wall paintings, mineral deposits, saltpetre and inorganic stains.",
//         "FEATURES": [
//           "Concentrated product to be diluted in water",
//           "Water base",
//           "Ordinary and extraordinary maintenance",
//           "Acidic nature & ideal for outdoor"
//         ],
//         "PACKAGING_UNITS_SHELF_LIFE": [
//           { "Size": "1 Ltr", "Shelf_Life": "24 months" }
//         ],
//         "COLOUR": "Red Liquid"
//       }
//     ],
//     "suppliers_info": {
//       "Made_in": "India with German Technology",
//       "Supplied_from": "Germany"
//     }
//   }

//   return (
//     <div className="product-catalog">
//       <br/>
//       <h1>Tenex Product</h1>
//       <div className="product-container">
//         {productsData.products.map((product, index) => (
//           <div key={index} className="product-card1">
//             <div>
//             <h2>{product.name}</h2>
//             <p><strong>Description:</strong> {product.description}</p>
//             <p><strong>Field of Application:</strong> {product.Field_of_Application}</p>
//             <p><strong>Features:</strong></p>
//             <ul>
//               {product.FEATURES.map((feature, index) => (
//                 <li key={index}>{feature}</li>
//               ))}
//             </ul>
//             <p><strong>Packaging Units Shelf Life:</strong></p>
//             <ul>
//               {product.PACKAGING_UNITS_SHELF_LIFE.map((unit, index) => (
//                 <li key={index}>{unit.Size} - Shelf Life: {unit.Shelf_Life}</li>
//               ))}
//             </ul>
//             <p><strong>Colour:</strong> {product.COLOUR}</p>
//             </div>
//             <div>
//             <img src={img} alt="" width={100}/>
//             </div>
//           </div>
//         ))}
//       </div>
//       <Tenexmore/>
//     </div>
//   );
// }

// export default App;

// import React, { useEffect, useState } from 'react';
// import { Document, Page, pdfjs } from 'react-pdf';
// import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
// import home from '../../Images/Tenex.pdf';
// import './Tenex.css'; // Import CSS for styling

// // Enable PDF.js worker
// pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

// const PDFThumbnailViewer = () => {
//     const [file, setFile] = useState(null);
//     const [numPages, setNumPages] = useState(null);
//     const [viewportWidth, setViewportWidth] = useState(window.innerWidth);

//     useEffect(() => {
//         window.addEventListener('resize', handleResize);
//         return () => {
//             window.removeEventListener('resize', handleResize);
//         };
//     }, []);

//     const handleResize = () => {
//         setViewportWidth(window.innerWidth);
//     };

//     useEffect(() => {
//         getPdf();
//     }, []);

//     const getPdf = () => {
//         setFile(home);
//     };

//     const onDocumentLoadSuccess = ({ numPages }) => {
//         setNumPages(numPages);
//     };

//     const calculatePageWidth = () => {
//         // Adjust page width dynamically based on viewport width
//         const maxWidth = 0.9 * viewportWidth; // Adjust as needed
//         const calculatedWidth = Math.min(maxWidth, 900); // Set maximum width
//         return calculatedWidth;
//     };

//     return (
//         <div className="pdf-viewer-container"> {/* Apply styling for full-screen */}
//             {file && (
//                 <div className="pdf-document-container"> {/* Apply styling for PDF document */}
//                     <Document file={file} onLoadSuccess={onDocumentLoadSuccess}>
//                         {Array.from(new Array(numPages), (el, index) => (
//                             <div key={`page_${index + 1}`} className="pdf-page">
//                                 <Page pageNumber={index + 1} width={calculatePageWidth()} />
//                             </div>
//                         ))}
//                     </Document>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default PDFThumbnailViewer;


import React from 'react'
import anti from '../../Images/Tenax-Anti-Efflorecence-Product.webp'
// import anti from '../images/Tenax-Anti-Efflorecence-Product.webp'
import cera from '../../Images/tenax-cera.jpg'
import marble from '../../Images/tenax-marble-joint-filling.webp'
import trans from '../../Images/thassos-transparent-tenax-original.webp'
import clear from '../../Images/tenax-eliox-extra-clear-epoxy.jpeg'
import color from '../../Images/colorent.jpg'
import strong from '../../Images/tenax_strongedge_express_flowing.webp'
import ager from '../../Images/tenex ager.webp'
import fluaida from '../../Images/cera-fluada.webp'
import proseal from '../../Images/proseal-tenax-original.webp'
import product from '../../Images/product AAB.jpeg'
import hydrex from '../../Images/hydrex.jpg'
import powder from '../../Images/powder polish.jpeg'
import skudo from '../../Images/skudo universal plus.jpeg'
import easywet from '../../Images/easywet-colour-enhancing-sealer-tenax.webp'
import neuroplus from '../../Images/neuroplus.jpg'
import epoxy from '../../Images/tenex500.webp';
import bravo from '../../Images/tanex-bravo-disincruster-strong-acid.webp'
import bravo2 from '../../Images/tanex-bravo-degreaser-degreasing-liquid.webp'
import briotop from '../../Images/Tenax-Brio-Top-Product.webp';
import '../Tenax.css'


function Tenax() {
    const data = [
        {
            "title": "Back Sealer",
            "img": anti,
            "name": "TENAX Anti-Efflorescence",
            "description": "For protecting porous and absorbent surface against the rise of ferrous salts and other contaminants, such as staining left by rain water.May result from the back of the stone.It is tested for capillary absorption with contrast liquid & adhesion strength according to UNI EN 105 - 12 - 2016 method.",
            "features": ["Water-based alkaline product", "It is ready to use & ensures anti-efflorescence", "Provides definitive treatment"],
            "shelfLife": "24 months",
            "packagingUnits": "1ltr",
            "colour": "White"
        },
        {
            "title": "Back Sealer",
            "img": cera,
            "name": "Roff Stoneguard WB",
            "description": "Water based penetrating sealer for porous & non-porous stones. It's transparent in nature & protects stone with advanced penetrating microbond technology.",
            "features": ["Non-staining", "Water and oil resistant", "Maintains the natural look of stone"],
            "shelfLife": "24 months",
            "packagingUnits": "1ltr",
            "colour": "Clear"
        },
        {
            "title": "General Mastics Colored",
            "img": strong,
            "name": "TENAX Solid & Liquid 3G Coloured Mastic",
            "description": "For gluing and filling the cracks & gaps of marbles and other stones. Liquid variant is liquid glue with good viscosity, recommended for horizontal applications. Solid variant is thixotropic glue with high viscosity, recommended for vertical application.",
            "features": ["Polishable and grindable in a very short time", "Has low shrinkage, good adhesion"],
            "shelfLife": "12 months",
            "packagingUnits": ["250 g", "500 g", "1.5 kg"],
            "colour": "White, Ivory"
        },
        {
            "title": "General Mastics Transparent",
            "img": marble,
            "name": "TENAX Thassos – Tropical",
            "description": "For gluing and filling cracks and gaps of white marbles family and similar stones like Thassos and Carrara.",
            "features": ["High thixotropic glue with high viscosity", "Good stability to sun light", "Very easy to stir and spread"],
            "shelfLife": "12 months",
            "packagingUnits": "1kg",
            "colour": "Transparent"
        },
        {
            "title": "General Mastics Transparent",
            "img": trans,
            "name": "TENAX Resina T8",
            "description": "For gluing and filling cracks & gaps in marbles & stones like Carrara and similar stones. Ideal for filling up travertines and similar types of stones for achieving a filled-transparent effect.",
            "features": ["Extra clear liquid resin with low viscosity", "Very low shrinkage", "Stable performance in sunlight", "Minimum reaction temperature 5°C"],
            "shelfLife": "6 months",
            "packagingUnits": "1ltr",
            "colour": "Transparent"
        },
        {
            "title": "General Mastics Transparent",
            "img": trans,
            "name": "TENAX Transparent Mastic",
            "description": "For gluing and filling the cracks & gaps of marbles, onyx and other stones. Liquid variant is liquid glue with good viscosity, recommended for horizontal applications. Solid variant is thixotropic glue with high viscosity, recommended for vertical application.",
            "features": ["Polishable and grindable in a very short time", "Has low shrinkage, good adhesion"],
            "shelfLife": "12 months",
            "packagingUnits": "1kg",
            "colour": "Transparent"
        },
        {
            "title": "Premium Epoxy Mastic",
            "img": clear,
            "name": "TENAX Eilox A+B (Epoxy Mastic)",
            "description": "Extra clear thixotropic paste for gluing and filling cracks & gaps of marble, stone, granite, quartz & ceramics.",
            "features": ["Approved for food contact – non-toxic to long term usage of marbles", "Easy dosing & easy to spread", "Good adhesion", "Suitable for outdoor and indoor", "Very less or no yellowing"],
            "shelfLife": "24 months",
            "packagingUnits": ["Part A - 1.5 kg + Part B - 0.75 kg", "Part A - 1 kg + Part B - 0.5 kg"],
            "colour": "Extra Clear/Whitish"
        },
        {
            "title": "Colorants",
            "img": color,
            "name": "TENAX Universal Colour Pastes",
            "description": "A colouring paste for all the polyester, epoxy mastics and systems to match the color stone.",
            "features": ["Soft paste available in multiple shades", "Very easy to mix", "Universal Coloring"],
            "shelfLife": "12 months",
            "packagingUnits": "75g",
            "colour": "Multiple colors/shades"
        },
        {
            "title": "epoxy_resin_hardener",
            "img": product,
            "name": "TENAX Epoxy Resin 943/ Epoxy Resin 943 S/ Resina 911",
            "description": "For resining and treating natural stone. It is a liquid epoxy resin coat for corner to corner application ranging from medium to low viscosity with high penetration, gives adhesion and strength to the indicated stone. In addition, there are sets of recommended hardeners - Induritore - MFSO21, BIPO14H, BIP25H, compatible with the resin for different working times and different applications.",
            "features": ["Gives shining/wet look", "Strengthen stones"],
            "shelfLife": "24 months",
            "packagingUnits": ["Resina 943 - 1kg & 5kg BIP25H - 0.25 & 1kg"],
            "colour": "Water Clear"
        },
        {
            "img": epoxy,
            "name": "Resina 943 + BIPO14 Mixing Ratio - (100:30)",
            "packagingUnits": ["Resina 943 - 1kg & 5kg BIPO14 - 0.3 & 1kg"],
            "colour": "Clear",
            "features":['Resina 943 + MFSO21 Mixing Ratio - (100:18)',"Resina 943 - 1kg & 5kg MFSO21 - 1kg",
            "colour"-"Light Yellow"
            ]
        },
        // {
        //     "name": "Resina 943 + MFSO21 Mixing Ratio - (100:18)",
        //     "packagingUnits": ["Resina 943 - 1kg & 5kg MFSO21 - 1kg"],
        //     "colour": "Light Yellow"
        // },
        // {
        //     "name": "Resina 911 + MIP25G Mixing Ratio - (100:25)",
        //     "packagingUnits": ["Resina 911 - 1kg & 5kg MIP25G - 1kg"],
        //     "colour": "Hazy"
        // },
        {
            "title": "polish_powder_polish",
            "img": powder,
            "name": "TENAX Polvere Lucidante Gialla",
            "description": "Marble stone polishing powder to increase the shine of the marble when applied at the end of polishing with help of disc machine.",
            "features": ["Ensures perfect mechanical & chemical polishing effect", "Ideal for delicate marbles & gives long lasting polished finish", "10 tablespoons of the product per sq. mtr. to be polished"],
            "shelfLife": "NA",
            "packagingUnits": "1kg",
            "colour": "Yellow coarse powder, Cream Fine powder"
        },
        {
            "title": "polish_powder_polish",
            "img": fluaida,
            "name": "TENAX Cera Fluida",
            "description": "For enhancing the polish on stone. Recommended usage immediately after completion of polishing process on stones such as travertine, marble, natural stones, granite, agglomerates, and terrazzo.",
            "features": ["It is a very concentrated wax liquid", "May increase the polish from 5-10 to 10-15 point of gloss", "Good for use in outdoor condition", "Gives a coverage of 20-30 sqm/L"],
            "shelfLife": "24 months",
            "packagingUnits": "1Ltr",
            "colour": "White/ Yellow"
        },
        {
            "title": "top_sealer",
            "img": skudo,
            "name": "TENAX Skudo Universal Plus",
            "description": "A water-based water and oil proof anti-stain protector. It penetrates deeper into the micro-porosities & prevents liquids from penetrating. Permeable to vapors and letting materials breath.",
            "features": ["Gives natural look", "Food grade approvals", "Indoor & outdoor", "Quick drying, allows application on not fully dried substrates"],
            "shelfLife": "12 months",
            "packagingUnits": "1Ltr",
            "colour": "Colorless"
        },
        {
            "title": "top_sealer",
            "img": proseal,
            "name": "TENAX Proseal",
            "description": "Solvent-based oil proof and water proof penetrating sealer for protecting in depth granite marble, limestone, sandstone and terracotta. Generally recommended for quartz-based materials and materials with mixed presence of silicates and carbonates.",
            "features": ["Recommended for indoor and outdoor application", "Performs well on polished, honed, sandblasted and flamed surfaces", "Gives a coverage of 20-30gr/m2l"],
            "shelfLife": "12 months",
            "packagingUnits": "1Ltr",
            "colour": "Colorless"
        },
        {
            "title": "top_sealer",
            "img": hydrex,
            "name": "TENAX Hydrex",
            "description": "An anti-stain which block micro-porosities so that prevents liquids from penetrating inside stone. It protects from water based stains.",
            "features": ["Gives natural look", "Indoor & outdoor", "Suitable for polished marbles like: Carrara, Thassos, Trani, Botticino, Marfill and similar material to vapors letting materials breath"],
            "shelfLife": "24 months",
            "packagingUnits": "1Ltr",
            "colour": "Transparent"
        },
        {
            "title": "top_sealer_enhancer",
            "img": ager,
            "name": "TENAX Ager",
            "description": "For enhancing colour / giving a wet look on stones and protecting from oil & water based stains. Suitable for surfaces which are polished, honed, sanded, brushed, flamed or rough.",
            "features": ["Colour enhancer & top sealer", "Both for indoor and outdoor applications", "Gives protection to the surfaces against oil and water", "Gives anti-graffiti upon accurately effect", "Gives a coverage of 20-30 gr/m2l"],
            "shelfLife": "24 months",
            "packagingUnits": "1Ltr",
            "colour": "Colourless"
        },
        {
            "title": "Enhancer",
            "img": easywet,
            "name": "TENAX Easywet",
            "description": "Suitable for aging all types of marbles, travertines, stones, granites and agglomerates in order to obtain a 'wet' effect for indoor.",
            "features": ["Colour enhancer / Wet Effect","Quick drying","Application on surfaces polished, honed, sanded, brushed, flamed or rough too" ],
            "shelf_life": "24 months",
            "packaging_units": "1Ltr",
            "colour": "Transparent"
          },
          {
            "title": "Enhancer",
            "img": neuroplus,
            "name": "TENAX Neroplus Nero / Trasparente",
            "description": "To darken the surface (black granite / stones) to make it darkens/brightens and more homogeneous.",
            "features": ["Darkens/brightens the colour","Make surface more homogeneous","Hides small spots","Gives a deep shine"],
            "shelf_life": "24 months",
            "packaging_units": "1Ltr",
            "colour": "Black (Nero), Transparent"
          },
          {
            "title": "Cleaner",
            "img": bravo2,
            "name": "TENAX Bravo Sgrassatore",
            "description": "Removes grease, synthetic & extraordinary organic stains (dirt like oil, fats, soap, paint, varnish, ink etc.) from acid sensitive stones - like non-glossy marble, limestone etc.",
            "features": ["Concentrated product to be diluted in water","Water base","Ordinary and extraordinary maintenance","Alkaline nature" ],"shelf_life": "24 months","packaging_units": "1Ltr","colour": "Orange Liquid"
          },
          {
            "title": "Cleaner",
            "img": bravo,
            "name": "TENAX Bravo Disincrostante",
            "description": "Concentrated detergent, suitable for the removal of dirt from the building sites such as joint residues, rust, traces of wall paintings, mineral deposits, saltpetre and inorganic stains.",
            "features": ["Concentrated product to be diluted in water","Water base","Ordinary and extraordinary maintenance","Acidic nature & ideal for outdoor"],
            "shelf_life": "24 months",
            "packaging_units": "1Ltr",
            "colour": "Red Liquid"
          },
          {
            "title": "Cleaner",
            "img": briotop,
            "name": "Tenax Brio Top",
            "description": "For quick and easy cleaning of marble, granite, engineered stone, countertops, vanity tops and all other solid surfaces.",
            "features": ["Alcohol-based cleaner","Formulated for everyday use","Can be used without rinsing","Does not leave halos","Quick drying"],"shelf_life": "24 months","packaging_units": "1Ltr", "colour": "Transparent"
          }
    ]

    return (
        <div className='main1' style={{background:'white',height:'100%'}}>
            {data.map((product, index) => (
                <div key={index} className="product1" style={{margin:'auto'}}>
                    {product.title && <h2 className='title1'>{product.title}</h2>}
                    {product.img && <img src={product.img} height={250} width={150} className='img' alt={product.name} />}
                    <h3>{product.name}</h3>
                    <p>{product.description}</p>
                    <strong>Features:</strong>
                    <p>{product.features}</p>
                    <ul>
                        {product.features && product.features.map((feature, i) => (
                            <p key={i}>{feature}</p>
                        ))}
                    </ul>
                    <div className='product-item1'>
                        {product.shelfLife && <p><strong>Shelf Life:</strong> {product.shelfLife}</p>}
                        {product.packagingUnits && <p><strong>Packaging Units:</strong> {Array.isArray(product.packagingUnits) ? product.packagingUnits.join(", ") : product.packagingUnits}</p>}
                        {product.colour && <p><strong>Colour:</strong> {product.colour}</p>}
                    </div>
                    <br/>
                    <br/>
                </div>
            ))}
        </div>
    );
}

export default Tenax;
