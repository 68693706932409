// import React from 'react';
// import './Landing.css';
// import { Link, useNavigate } from 'react-router-dom';
// import { Card, Row, Col, Button } from 'antd';
// import stellar from '../../Images/stellar-v2.png';
// import stellar2 from '../../Images/SP-100-MSD.png';
// import stellar3 from '../../Images/L-600-Series.png';
// import stellar4 from '../../Images/L-500-Series-240x300.png';
// import stellar5 from '../../Images/1776.png.png';
// import stellar6 from '../../Images/Dazzle-2.png';
// import stellar7 from '../../Images/2000.png';
// import steller8 from '../../Images/100Dua.png';
// const Categories = () => {
//     const products = [
//         {
//             "img": stellar,
//             "name": "STELLAR GROUT PRODUCT",
//             "description": "It is a single component, high performance modified joint filling grout, which offers excellent colour uniformity, durability, stain protection, and beautiful grout joints in an easy-to-use, non-sag formula, with no hassle of mixing two packs or any other liquids like water or latex liquid.",
//             "applications": "STELLAR Grout is best suited for internal & external tile joints grouting of residential / commercial installations where materials must resist physical abuse, shock & chemical attack. Excellent for external swimming pool & water bodies. It is NOT a waterproof material for joints or surfaces.",
//             "advantages": [
//                 "Single Component",
//                 "Suitable for joints of 1.5 mm to 12 mm",
//                 "Non-sag formula for floors and walls",
//                 "Designed for exterior use"
//             ],
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 {
//                     "type": "1/5th unit",
//                     "weight": "1 kg"
//                 },
//                 {
//                     "type": "1 unit",
//                     "weight": "5 kg"
//                 }
//             ],
//             "colour": "Available in 5 colours",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.6",
//                     "EN 13888: CG1",
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": stellar2,
//             "name": "STELLAR Stainfree Grout SP-100 DUO",
//             "description": "SP-100™ DUO Stainfree Grout for Floor N’ Wall is a Stain Resistant, Water Resistant & Germ Resistant grout for Floor & Wall. SP-100™ DUO Tile-Joint is suitable for ceramic tiles, vitried tiles, marble and stone, especially in stain prone areas such as kitchens and washrooms.",
//             "applications": [
//                 "Kitchens",
//                 "Bathrooms",
//                 "Swimming pools",
//                 "Fountains",
//                 "Water bodies",
//                 "Hospitals",
//                 "Operation theatres",
//                 "Sterilization rooms",
//                 "Restaurants",
//                 "Cafeterias",
//                 "Light industrial applications",
//                 "High trafc areas"
//             ],
//             "advantages": [
//                 "100% stainfree",
//                 "Uniform colour",
//                 "Chemical and temperature resistant",
//                 "Non-cracking or powdering"
//             ],
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 {
//                     "type": "1/5th unit",
//                     "weight": "1 kg"
//                 },
//                 {
//                     "type": "1 unit",
//                     "weight": "5 kg"
//                 }
//             ],
//             "colour": "Available in a range of more than 10 colours",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.3",
//                     "EN 13888: RG2",
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": stellar3,
//             "name": "LATICRETE 600 SERIES UNSANDED GROUT",
//             "description": "A carefully prepared blend of portland cement, graded aggregates, polymer fortifiers and colour-fast pigments, it is designed for joint width 1mm to 3mm, for porous & absorbent type tiles, non-vitreous clay tiles, and marble & glazed wall tiles. Suitable for internal and external applications.",
//             "applications": "Residential, commercial, external facades",
//             "advantages": [
//                 "For tile joints 1 - 3mm",
//                 "Uniform colour",
//                 "Hard and durable",
//                 "Economical"
//             ],
//             "mixingRatio": "Use approx. 400 ml of water or LATICRETE 1776 Grout Admix Plus for 1 kg of LATICRETE 600 Series Unsanded Grout.",
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 {
//                     "type": "500g",
//                     "weight": "500g"
//                 },
//                 {
//                     "type": "1 kg",
//                     "weight": "1 kg"
//                 },
//                 {
//                     "type": "10 kg",
//                     "weight": "10 kg"
//                 }
//             ],
//             "colour": "Available in a range of more than 36 colours",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.6",
//                     "EN 13888: CG1",
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": stellar4,
//             "name": "LATICRETE 500 SERIES SANDED GROUT",
//             "description": "LATICRETE 500 Series Sanded Grout is made of polymer blended portland cement, graded aggregates, fortifiers and colour-fast pigments, it provides a tile joint that is strong, hard and durable. Designed for joint width of 3mm to 12mm for non-absorbent and vitreous tiles, it is suitable for interior and exterior use.",
//             "applications": "Residential, commercial, external facades",
//             "advantages": [
//                 "For tile joints 3-12mm",
//                 "Uniform colour",
//                 "Hard and durable",
//                 "Economical"
//             ],
//             "mixingRatio": "Use approx. 2 ltr of LATICRETE 1776 Grout Admix Plus for 10 kg of 500 Series Sanded Grout.",
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 {
//                     "type": "10 kg",
//                     "weight": "10 kg"
//                 }
//             ],
//             "colour": "Available in a range of more than 36 colours",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.6",
//                     "EN 13888: CG1",
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": stellar5,
//             "name": "LATICRETE 1776 GROUT ADMIX PLUS",
//             "description": "LATICRETE 1776 Grout Admix Plus is specially designed Latex Admix to be used with Sanded & Unsanded Grout.",
//             "applications": "It improves the performance of portland cement grouts for all types of ceramic tile, brick, marble, natural stone and agglomerate stone installations.",
//             "advantages": [
//                 "Colour-fastness",
//                 "Flexibility",
//                 "Compressive strength",
//                 "Shock and chemical resistance",
//                 "Reduces water absorption",
//                 "Easy to clean"
//             ],
//             "coverage": "Varies by application.",
//             "packaging": [
//                 { "type": "200 ml", "weight": "pails" },
//                 { "type": "400 ml", "weight": "pails" },
//                 { "type": "5 ltr", "weight": "pails" },
//                 { "type": "20 ltr", "weight": "pails" }
//             ],
//             "compliance": {
//                 "standards": [
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": stellar6,
//             "name": "LATAPOXY Dazzle™ Grout for Floor N’ Wall",
//             "description": "LATAPOXY Dazzle™ Grout for Floor N’ Wall is an epoxy grout specifically designed to enhance aesthetics and performance. It is suitable for use with ceramic tiles, vitrified tiles, glass mosaic tiles, designer tiles and natural stone.",
//             "applications": [
//                 "Restaurants",
//                 "Hotels",
//                 "Kitchens",
//                 "Bathrooms",
//                 "Lobbies",
//                 "Swimming Pools",
//                 "Showrooms"
//             ],
//             "advantages": [
//                 "Good aesthetic appeal",
//                 "Stainfree",
//                 "Strong & durable",
//                 "Chemical & temperature resistant"
//             ],
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 { "type": "Net Weight:1/4 unit (1.25 kg)", "weight": "pails" },
//                 { "type": "1 unit (5 kg)", "weight": "pails" }
//             ],
//             "components": [
//                 "Hardener",
//                 "Resin (Part A and Part B)",
//                 "Dazzle Coloured Filler Powder (Part C)"
//             ],
//             "colour": "Available in 10 colours and 3 finishes - Gold, Silver and Copper",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.3",
//                     "EN 13888: RG2",
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": stellar7,
//             "name": "LATAPOXY 2000 INDUSTRIAL EPOXY",
//             "description": "Three part, 100% solid epoxy grout specifically formulated for maximum chemical and high temperature resistance. It is recommended for extreme industrial and commercial applications such as dairies, food processing units, commercial kitchens, breweries, beverage plants, pharmaceuticals, and meat packaging plants.",
//             "applications": "Industrial food processing plants, beverage plants, battery plants and environments requiring high temperature and chemical resistance.",
//             "advantages": [
//                 "Heavy duty industrial grout",
//                 "Superior chemical & heat resistance",
//                 "Water cleanable & fast curing",
//                 "Highly resistant to bacteria attack"
//             ],
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 { "size": "#2 unit (10 kg)", "type": "pails" }
//             ],
//             "components": [
//                 "Hardener",
//                 "Resin (Part A and Part B)",
//                 "Coloured Filler Powder (Part C)"
//             ],
//             "colour": "Black and Grey",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.3",
//                     "EN 13888: RG2",
//                     "VOC: Very low"
//                 ]
//             }
//         },
//         {
//             "img": steller8,
//             "name": "LATAPOXY 2000 INDUSTRIAL EPOXY",
//             "description": "Three part, 100% solid epoxy grout specifically formulated for maximum chemical and high temperature resistance. It is recommended for extreme industrial and commercial applications such as dairies, food processing units, commercial kitchens, breweries, beverage plants, pharmaceuticals, and meat packaging plants.",
//             "applications": "Industrial food processing plants, beverage plants, battery plants and environments requiring high temperature and chemical resistance.",
//             "advantages": [
//                 "Heavy duty industrial grout",
//                 "Superior chemical & heat resistance",
//                 "Water cleanable & fast curing",
//                 "Highly resistant to bacteria attack"
//             ],
//             "coverage": "Varies with tile size and grout width",
//             "packaging": [
//                 { "size": "#2 unit (10 kg)", "type": "pails" }
//             ],
//             "components": [
//                 "Hardener",
//                 "Resin (Part A and Part B)",
//                 "Coloured Filler Powder (Part C)"
//             ],
//             "colour": "Black and Grey",
//             "compliance": {
//                 "standards": [
//                     "ANSI: A 118.3",
//                     "EN 13888: RG2",
//                     "VOC: Very low"
//                 ]
//             }
//         }
//     ]
//     const navigate = useNavigate();
//     return (
//         <div>
//             <h1 style={{ textAlign: 'center' }}>Our Products</h1>
//             <Row className='category_row' >
//                 {products.map((product, index) => (
//                     <Col key={index} xs={24} sm={12} md={8} lg={6} xl={6}>
//                         <Card className='Categories_main_card'>
//                             <img src={product.img} alt="" />
//                             <h3>{product.name}</h3>
//                             <Button onClick={() => { navigate("/detail", { state: product }) }}>More Details</Button>
//                         </Card>
//                         <br />
//                     </Col>
//                 ))}
//             </Row>
//         </div>
//     );
// }
// export default Categories

import React from 'react';
import { Card, Collapse } from 'antd';
import DetailsgetModal from '../DtailsPage/DetailsgetModal'
import Location from './Location'

const { Panel } = Collapse;

const AboutFAQCards = () => {
  return (
    <div className="card-container">
      <Card title="About SHREE KRISHNA POWER TOOLS" className="card" style={{width:'100%'}} >
        <p>
          SHREE KRISHNA POWER TOOLS in Pachpedi Naka, Raipur-chhattisgarh is known to satisfactorily cater to the demands of its customer base. The business came into existence in 2000 and has, since then, been a known name in its field. It stands located at Near Kamla Woman Hospital, Ring Road No.1, Pachpedi Naka-492001. Near Kamla Woman Hospital is a prominent landmark in the area and this establishment is in close proximity to the same. It has earned stamps like Jd Verified, Jd Pay substantiating the credentials of the business. The business strives to make for a positive experience through its offerings. The accepted modes of payment such as Cash make every business transaction easy and seamless, contributing to making the entire process even more effective.
        </p>
        <p>
          Customer centricity is at the core of SHREE KRISHNA POWER TOOLS in Pachpedi Naka, Raipur-chhattisgarh and it is this belief that has led the business to build long-term relationships. Ensuring a positive customer experience, making available goods and/or services that are of top-notch quality is given prime importance.
        </p>
        <p>
          India’s leading B2B market place, Jd Mart ensures engaging in business activities is a seamless process for small and medium enterprises as well as large businesses. In a wake to enable these businesses to reach their audience, this portal lets them showcase their offerings in terms of the products and/or services through a digital catalogue. This business has a wide range of product offerings and the product/catalogue list includes Acrylic Emulsion Paint 5569, Emulsion Paint 4158, Sheenlac Enamel Paint, Nerolac Excel Tile Guard Emulsion Paint, Polymer Paint Emulsions & Binders etc.
        </p>
      </Card>
     
      <Card title="Frequently Asked Questions" className="card" style={{width:'100%'}}>
        <Collapse accordion defaultActiveKey={['3']}>
          <Panel header="Which is the nearest landmark?" key="2">
            <p>You can easily locate the establishment as it is in close proximity to Near Kamla Woman Hospital.</p>
          </Panel>
          <Panel header="What are its hours of operation?" key="3">
            <p>
              The establishment is functional on:<br />
              Monday to Suturday : 8:30 AM - 8:30 PM<br />
            </p>
          </Panel>
        </Collapse>
      </Card>

      <Card title="Company Information" className="card" style={{width:'100%'}}>
        <p>
          <strong>SHREE KRISHNA POWER TOOLS</strong><br />
          Ring Road No.1, Pachpedi Naka, Raipur-chhattisgarh - 492001 (Near Kamla Woman Hospital)<br />
          <DetailsgetModal /><br />
          <a href="https://shreekrishnapowertools.com/">Website Link</a><br />
          <strong>Open Now :</strong> Mon - Sat: 8:30 am - 8:30 pm, Sun: 8:30 am - 1:00 pm<br />
          <strong>Business Statutory Details</strong><br />
          <strong>Year of Establishment:</strong> 2000<br />
          <strong>GSTIN:</strong> 22AIPPL8164R1ZE<br />
          <strong>Mode of Payment:</strong> Cash and UPI
        </p>
        <Location/>
      </Card>
    
    </div>
  );
}

export default AboutFAQCards;
