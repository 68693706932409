import React from 'react'
import Waterproofinghome from '../../Images/Waterproofinghome.png';
import screedhome from '../../Images/screedhome.jpg';
import Tileadhesive from '../../Images/Tileadhesive.png';
import tileSton from '../../Images/tileSton.jpg';
function Insallsystem() {
    return (
        <div>
            <h1 style={{textAlign:'center',color:'blue'}}>Installation Systems</h1>
            <div style={{ display: 'flex',backgroundColor:'gray',height:'300px'}}>
                <div style={{ position: 'relative', height: '200px', width: '18%', margin: "auto", margin: "auto" }}>
                    <img src={Waterproofinghome} alt="" style={{ position: 'absolute', width: '100%', height: '200px' }} />
                    <div style={{ position: 'absolute', background: 'black', width: '100%', height: '100px', opacity: '0.9', top: '100px', color: 'white', textAlign: 'center' }}>
                        <p>Water Proofing Home</p>
                    </div>
                </div>
                <div style={{ position: 'relative', height: '200px', width: '18%', margin: "auto" }}>
                    <img src={screedhome} alt="" style={{ position: 'absolute', width: '100%', height: '200px' }} />
                    <div style={{ position: 'absolute', background: 'black', width: '100%', height: '100px', opacity: '0.9', top: '100px', color: 'white', textAlign: 'center' }}>
                        <p>screed home</p>
                    </div>
                </div>
                <div style={{ position: 'relative', height: '200px', width: '18%', margin: "auto" }}>
                    <img src={Tileadhesive} alt="" style={{ position: 'absolute', width: '100%', height: '200px' }} />
                    <div style={{ position: 'absolute', background: 'black', width: '100%', height: '100px', opacity: '0.9', top: '100px', color: 'white', textAlign: 'center' }}>
                        <p>Tile adhesive</p>
                    </div>
                </div>
                <div style={{ position: 'relative', height: '200px', width: '18%', margin: "auto" }}>
                    <img src={tileSton} alt="" style={{ position: 'absolute', width: '100%', height: '200px' }} />
                    <div style={{ position: 'absolute', background: 'black', width: '100%', height: '100px', opacity: '0.9', top: '100px', color: 'white', textAlign: 'center' }}>
                        <p>Tile Ston</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Insallsystem
