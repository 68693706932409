
import React from 'react';
import img from '../../Images/Sound-Control-UL.png';
import img1 from '../../Images/Floating-Floor.png';
import img2 from '../../Images/Gym-768x380.png';
const SoundControl = () => {
  const products = [
    {
      "img":img,
      "name": "SOUND CONTROL",
      "description": "MYK LATICRETE UL (underlayment) is a high-performance sound control mat that reduces impact noises through ceramic tiles, stone, hard wood, laminate wood, LVT, SPC and other hard surfacing materials.",
      "applications": [
        "Tiles: All types of tiles rated for minimum of commercial use.",
        "Wooden Floor - PUA 212 offers superior bonding strength",
        "Vinyl Floor-One step® method provides superior sound control for resilient floor coverings"
      ],
      "advantages": [
        "Mold, bacteria, fungi and water resistant",
        "Contains 88.5% post-consumer recycles rubber",
        "IIC rating – 14 to 22 per ASTM E2179",
        "Floorscore certified",
        "Isolates cracks up to 3mm",
        "Complete floor covering system warranty available"
      ],
      "packaging": "Product Roll Size UL03 1.2 x 15 m, UL05 1.2 x 9.1 m",
      "colour": ["Ocean blue", "Lemon yellow", "Silver grey", "Lava"]
    },
    {
      "img":img1,
      "name": "MYK LATICRETE GYM",
      "description": "MYK LATICRETE GYM is an all-in-one flooring system for gyms and fitness rooms. The mats are engineered to reduce structure-borne noise and vibration associated with fitness facilities in all types of constructions.",
      "applications": [
        "Gymnasiums", "Sports floors", "Yoga rooms", "Zumba rooms", "Fitness amenities"
      ],
      "advantages": [
        "Significantly reduces structure-borne vibration",
        "Low dynamic stiffness for improved low frequency response",
        "Decreased impact force exposure level as measured using heavy/hard impact sources",
        "Seamless installation"
      ],
      "packaging": "Gym X08: 1.2 x 7.6 m",
      "colour": ["Ocean blue", "Lemon yellow", "Silver grey", "Lava"]
    },
    {
      "img":img2,
      "name": "MYK LATICRETE Floating Floor",
      "description": "MYK LATICRETE Floating Floor is a robust dimpled rubber pad made from 92% recycled rubber material. Floating floor is used to provide high levels of impact and vibration insulation in floor / ceiling applications.",
      "applications": [
        "Continuous underlayment system that limits surface area contact down to 4% at 2 psi.",
        "Achieves low natural frequency with systems available as low as 6 Hz",
        "Can be safely loaded over a wide range up to 1450 psi",
        "Mold, bacteria, fungi, and water resistant"
      ],
      "advantages": [
        "Mechanical rooms", "Electrical rooms", "Garbage rooms", "Infinity pools", "Fitness amenities"
      ],
      "packaging": "Product Roll Size FF17 1.2 x 4.6 m, FF25 1.2 x 4.6 m",
    }
  ];

  return (
    <div className="product-page">
      {products.map((product, index) => (
        <div className="product-card" key={index}>
            <div className="product-image">
            <img src={product.img} alt={product.name} />
          </div>
          <h2>{product.name}</h2>
          <p>{product.description}</p>
          <p><strong>Applications:</strong></p>
          <ul>
            {product.applications.map((application, index) => (
              <li key={index}>{application}</li>
            ))}
          </ul>
          <p><strong>Advantages:</strong></p>
          <ul>
            {product.advantages.map((advantage, index) => (
              <li key={index}>{advantage}</li>
            ))}
          </ul>
          <p><strong>Packaging:</strong> {product.packaging}</p>
          {product.colour && (
            <p><strong>Colour:</strong> {product.colour.join(', ')}</p>
          )}
        </div>
      ))}
      <style>{`
        .product-page {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-around;
          gap: 20px;
          padding: 20px;
        }
        
        .product-card {
          background-color: #f2f2f2;
          color:black;
          padding: 20px;
          border-radius: 8px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
          width: 45%;
          min-width: 300px;
        }
        
        .product-card h2 {
          margin-top: 0;
        }
        .product-image {
          overflow: hidden;
          text-align:center;
          width: 80%;
          height: auto;
        }

        .product-image img {
          width:100%;
          height: 200px;
          object-fit: cover;
        }
        .product-card ul {
          margin-top: 5px;
          padding-left: 20px;
        }
        
        .product-card li {
          margin-bottom: 5px;
        }
      `}</style>
    </div>
  );
};

export default SoundControl;
