import React from 'react';

const MapComponent = () => {
  const mapUrl = "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d117253.46864304138!2d81.60573283125002!3d21.251384199999998!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a28dd17f6c98599%3A0xdddcfb8bec4b77a4!2sShree%20Krishna%20Power%20tools%2C%20Shitla%20Mandir%20Rd%2C%20Marble%20Market%2C%20Postal%20Colony%2C%20Tikrapara%2C%20Raipur%2C%20Mathpurena%2C%20Chhattisgarh%20492001!5e0!3m2!1sen!2sin!4v1597311062346!5m2!1sen!2sin";

  return (
    <div style={{ textAlign: 'center' }}>
      <iframe
        width="100%"
        height="400"
        frameBorder="0"
        style={{ border: 0 }}
        src={mapUrl}
        allowFullScreen=""
        aria-hidden="false"
        tabIndex="0"
        title="Google Map"
      ></iframe>
    </div>
  );
};

export default MapComponent;