
import React from 'react';
import img from '../../Images/Maxi-Seal-Plus.png';
import img1 from '../../Images/Maxi-Seal-Aqua.png';
import img2 from '../../Images/Maxi-Seal.png';
import img4 from '../../Images/Preservaplus.png';
import img6 from '../../Images/Preserva.png';
import img7 from '../../Images/Clenza-EG-2.png';
import img8 from '../../Images/Clenza-GA-3.png';
import img9 from '../../Images/Clenza-MC-2.png';
import img10 from '../../Images/Clenza-TS-1.png';
const StoneCare = () => {
  const sealers = [
    {
      "img": img,
      "name": "MaxiSeal Plus",
      "description": "MaxiSeal plus is a nano technology based impregnating sealer designed for the protection of all highly porous tile/stone and grout surfaces. This product imparts oleo phobic properties, highly resistant to oil, water, alkalis, acid staining and freeze-thaw damages. It penetrates into the surface and forms an invisible barrier by allowing vapour to escape. It keeps the natural colour of tile & stone intact. All surfaces treated with MaxiSeal plus are harder and less slippery. It is ideal for interior as well as exterior applications and recommended for granite, marble, sand stone, porous slate, brick and sanded grout.",
      "applications": ["For interior & exterior applications on tile and stone surfaces"],
      "advantages": [
        "Nano-impregnating technology",
        "High resistance to oil stains",
        "Weather resistant",
        "Superior coverage",
        "Economical",
        "Easy to use",
        "U.V. resistant",
        "Low VOC after application",
        "Resistance to stains, water & oil repellent"
      ],
      "coverage": "25 - 400 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"],
      "fieldTest": "It is highly recommended to perform field test to understand the absorption nature of the substrate. This will help maximize the performance and efficiency of MaxiSeal plus."
    },
    {
      "img": img1,
      "name": "MaxiSeal Aqua",
      "description": "MaxiSeal aqua is nano technology based impregnating sealer with water as a carrier designed for the protection of all highly porous tile/stone and grout surfaces. This product imparts oleo phobic properties, highly resistant to oil, water, alkalis and acid staining damages. It penetrates into the surface and forms an invisible barrier by allowing vapours to escape. It keeps the natural colour of tile & stone intact. All surfaces treated with MaxiSeal aqua are harder and less slippery. It is ideal for interior as well as exterior application and recommended for grout, ceramic tiles, porcelain tiles, granite, marble, lime stone and brick.",
      "applications": ["For interior & exterior applications on tile and stone surfaces"],
      "advantages": [
        "Nano-impregnating technology",
        "High resistance to oil stains",
        "Weather resistant",
        "Superior coverage",
        "Economical",
        "Easy to use",
        "U.V. resistant",
        "Low VOC after application",
        "Water repellent"
      ],
      "coverage": "25 - 400 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"],
      "fieldTest": "It is highly recommended to perform field test to understand the absorption nature of the substrate. This will help maximize the performance and efficiency of MaxiSeal aqua."
    },
    {
      "img": img2,
      "name": "MaxiSeal",
      "description": "MaxiSeal is a nano technology based impregnating sealer designed for the protection of all medium to dense porous tile/stone and grout surfaces. This product is formulated for resistance against water, alkalis, acid staining and freeze-thaw damages. It penetrates into the surface and forms an invisible barrier by allowing vapour to escape. It keeps the natural colour of tile & stone intact. All surfaces treated with MaxiSeal are harder and less slippery. It is ideal for interior as well as exterior applications and recommended for grout, brick & masonry surfaces, granite, marble, ceramic and porcelain tiles.",
      "applications": [
        "For interior & exterior applications on tile and stone surfaces"
      ],
      "advantages": [
        "Nano-impregnating technology",
        "Weather resistant",
        "Superior coverage",
        "Economical",
        "Easy to use",
        "U.V. resistant",
        "Low VOC after 72 hrs of application",
        "Resistance to stains water repellent"
      ],
      "coverage": "25 - 500 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"],
      "fieldTest": "It is highly recommended to perform field test to understand the absorption nature of the substrate. This will help maximize the performance and efficiency of MaxiSeal."
    },
    {
      "img": img,
      "name": "LATICRETE 190 Sealer",
      "description": "LATICRETE 190 Sealer is a nano technology based penetrating sealer which forms an invisible water repellent coating. Applied on natural stone, marble, brick, limestone and mortar joints, it will seal the surface to reduce staining and water absorption. It will not change the colour of the stone and ceramic tile.",
      "uses": [
        "Interior and exterior sealer to protect installations",
        "Seals mortar and plaster",
        "Reduces the effect of efflorescence",
        "Can be used as a grout release by treating ceramic tile before the application of grout",
        "Repels water and rain"
      ],
      "applications": [
        "Residential & commercial, ideal for soft and natural stone, marble and porous tiles."
      ],
      "notRecommended": "NOT RECOMMENDED FOR NATURAL STONES INSTALLED WITH TRADITIONAL CEMENT SLURRY SYSTEM.",
      "advantages": [
        "Weather resistance - protects stone, brick and tile",
        "Easy to apply - no special tools required",
        "Water-based, non-flammable",
        "VOC: Very low"
      ],
      "coverage": "Approximately 75 sq. ft. per litre.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"]
    },
    {
      "img": img4,
      "name": "Preserva plus",
      "description": "Preserva plus is a single component solution for enhancing and sealing the natural colour of flamed, tumbled and textured stone surfaces. Preserva plus functions on the surface of the substrates. It penetrates into the surface and forms an invisible barrier by allowing vapour to escape. It enriches and gives a wet look to the natural color of the stone and tile. It protects tile/stone surfaces against oil based stains. All surfaces treated with Preserva plus are harder and less slippery. It is ideal for interior as well as exterior application and recommended for Flamed Granite, Flamed Limestone, Tumbled Marble, Textured Natural Stone.",
      "applications": [
        "For interior & exterior applications on textured natural stone surfaces."
      ],
      "advantages": [
        "Nano-impregnating technology",
        "Weather resistant",
        "Superior coverage",
        "Economical",
        "Easy to use",
        "U.V. resistant",
        "Low VOC after application",
        "Water repellent",
        "Stain resistant",
        "Oil repellent"
      ],
      "coverage": "25 - 400 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"],
      "fieldTest": "It is highly recommended to perform field test to understand the absorption nature of the substrate. This will help maximize the performance and efficiency of Preserva plus."
    },
    {
      "img": img6,
      "name": "Preserva",
      "description": "Preserva enhances the natural colour of flamed, tumbled and textured stone surfaces. When combined with our range of Sealers, it preserves and enhances the original beauty of the unsealed natural stone. Preserva functions on surface of the substrates. It penetrates into the surface and forms an invisible barrier by allowing vapour to escape. It enriches and gives a wet look to the natural colour of the stone and tile. All surfaces treated with Preserva are harder and less slippery. It is ideal for interior as well as exterior applications and recommended for Flamed Granite, Flamed Limestone, Tumbled Marble and Textured Natural Stone.",
      "applications": [
        "For interior & exterior applications on textured natural stone surfaces."
      ],
      "advantages": [
        "Nano-impregnating technology",
        "Weather resistant",
        "Superior coverage",
        "Economical",
        "Easy to use",
        "U.V. resistant",
        "Low VOC after application",
        "Wet look"
      ],
      "coverage": "25 - 400 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"],
      "fieldTest": "It is highly recommended to perform field test to understand the absorption nature of the substrate. This will help maximize the performance and efficiency of Preserva."
    },
    {
      "img": img7,
      "name": "Clenza EG™",
      "description": "Clenza™ EG is a non-acid cleaner to remove epoxy grout haze from tile, concrete and stone surfaces. The unique non-acidic formula makes it safe to be used on marble and other calcium based stones. Clenza™ EG does not leave behind residue or any harmful deposits. In addition to epoxy, Clenza™ EG will also remove acrylics, gums, varnishes, lacquers etc. Clenza™ EG is fast acting and easy to use.",
      "applications": [
        "Ceramic tiles",
        "Vitrified tiles",
        "Natural stone",
        "Concrete"
      ],
      "advantages": [
        "Fast acting",
        "Non-acidic",
        "Non-corrosive",
        "Easy to use"
      ],
      "coverage": "15 - 50 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"]
    },
    {
      "img": img8,
      "name": "Clenza GA™",
      "description": "Clenza™ GA is an acidic cleaner which can be used to remove grout and adhesive haze, hard water deposit and other hard stains from tile and stone surfaces. Clenza™ GA is water soluble and can be used at desired concentration levels for stain removal. Clenza™ GA can be used in interior as well as exterior applications.",
      "applications": [
        "Ceramic tiles",
        "Vitrified tiles",
        "Concrete",
        "Masonry",
        "Glass tile"
      ],
      "advantages": [
        "Fast acting",
        "Non-acidic",
        "Non-corrosive",
        "Easy to use",
        "Economical"
      ],
      "coverage": "15 - 50 sq.ft. per litre depending on porosity of the substrate, degree of efflorescence, stains and dilution",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"]
    },
    {
      "img": img9,
      "name": "Clenza MC™",
      "description": "Clenza™ MC is a non-toxic cleaner which can be used for daily maintenance of marble flooring. The neutral pH cleaner is most suitable for cleaning marbles and other calcium based stones. Clenza™ MC is also recommended for regular maintenance of sealed stone surfaces.",
      "applications": [
        "All types of marble surfaces"
      ],
      "advantages": [
        "Interior & exterior use",
        "Economical",
        "Fast acting",
        "Easy to use"
      ],
      "coverage": "50 - 150 sq.ft. per litre depending on porosity of the substrate.",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"]
    },
    {
      "img": img,
      "name": "Clenza AQ™",
      "description": "Clenza™ AQ is a concentrate to be diluted with potable water as per need to clean all surfaces. It is a non-toxic cleaner which can be used for daily maintenance and to remove tough stains. This neutral pH cleaner is most suitable for cleaning marble and other calcium based stones. Clenza™ AQ is also recommended for regular maintenance of sealed stone surfaces. It is effective and easy to use.",
      "applications": [
        "Interior & exterior areas",
        "Kitchens",
        "Bathrooms",
        "Counter Tops"
      ],
      "advantages": [
        "Non-acidic",
        "Non-corrosive",
        "Bio-degradable"
      ],
      "coverage": "250 – 2500 sq.ft. per litre depending on porosity of the substrate, dilution and stains",
      "packaging": ["1 ltrs. cans", "5 ltrs. cans", "20 ltrs. pails"]
    },
    {
      "img": img,
      "name": "Clenza TC™",
      "description": "Clenza™ TC is a fast acting acid based cleaner for regular cleaning of dirt and stains on tile surfaces. Clenza™ TC is designed for daily maintenance of tiles. It is ideal for interior and exterior use.",
      "applications": [
        "Unglazed Ceramic Tile",
        "Glazed Ceramic Tile",
        "Vitrified Tile",
        "Porcelain Tile",
        "Glass Tile"
      ],
      "advantages": [
        "Cleans dirt and removes stains",
        "Easy to use",
        "Fast acting"
      ],
      "packaging": ["500 ml", "1 ltr bottles", "5 ltr can"]
    },
    {
      "img": img10,
      "name": "Clenza TS™",
      "description": "Clenza TS™ Tap and Shower Cleaner is a specialty cleaner designed to remove hard water stains, limescale stains and stubborn soap scum from CP fittings and sanitary ware.",
      "applications": [
        "Speciality cleaner for CP fittings & sanitary ware"
      ],
      "advantages": [
        "Restore shine & gloss",
        "Removes stubborn soap scum, hard water stains and limescale stains"
      ],
      "packaging": ["200 ml", "450 ml spray bottles"]
    }

  ];

  return (
    <div className="sealers-page">
      <div className='div_head'>
        <h1>Stone Care</h1>
        <p>
          MYK LATICRETE offers a full range of tile and stone care products that will add years, beauty and care to your stone and tile installation. Sealers, Enhancers and Cleaners have specially formulated solutions that offer the best protection, cost-effectiveness and ensure longevity of tiles and stones in their natural form. Sealers offer protection by creating a barrier between the contaminants and surface; they provide stain resistance and prolonged life to the surface. Enhancers are used to rejuvenate the colour and appearance of worn, faded tiles and stones. Enhancers provide long-lasting protection against oil, grease and stains. Cleaners are routine cleaning substances that are non-acidic and won’t damage stone, tile or grout.
        </p>
        <div className='div_headings'>
          <h2>Stone Care Products</h2>
        </div>
      </div>
      {sealers.map((sealer, index) => (
        <div className="sealer-card" key={index}>
          <div className="product-image">
            <img src={sealer.img} alt={sealer.name} />
          </div>
          <h2>{sealer.name}</h2>
          <p>{sealer.description}</p>
          <p><strong>Applications:</strong> {sealer.applications.join(', ')}</p>
          <p><strong>Advantages:</strong></p>
          <ul>
            {sealer.advantages.map((advantage, index) => (
              <li key={index}>{advantage}</li>
            ))}
          </ul>
          <p><strong>Coverage:</strong> {sealer.coverage}</p>
          <p><strong>Packaging:</strong> {sealer.packaging.join(', ')}</p>
          <p><strong>Field Test:</strong> {sealer.fieldTest}</p>
        </div>
      ))}
      <style jsx>{`
                .sealers-page {
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-around;
                    gap: 20px;
                    padding: 20px;
                }
                .div_head{
                    background-color: #f2f2f2;
                    text-align: center;
                    }
                    .div_headings{
                    background-color:blue;
                    color:white;
                    }
                .sealer-card {
                    background-color: #f2f2f2;
                    color:black;
                    padding: 20px;
                    border-radius: 8px;
                    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
                    width: 45%;
                    min-width: 300px;
                }
                .product-image {
                    overflow: hidden;
                    text-align:center;
                    width: 100%;
                    height: auto;
                    }

                    .product-image img {
                    width:50%;
                    height: auto;
                    object-fit: cover;
                    }
                .sealer-card h2 {
                    margin-top: 0;
                }
                
                .sealer-card ul {
                    margin-top: 5px;
                    padding-left: 20px;
                }
                
                .sealer-card li {
                    margin-bottom: 5px;
                }
            `}</style>
    </div>
  );
};

export default StoneCare;
